import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import ProductsRoutes from "./productsRoutes/ProductsRoutes";
import ContactRoutes from "./contactRoutes/ContactRoutes";
import AuthRoutes from "./authRoutes/AuthRoutes";
import FaqsRoutes from "./faqsRoutes/FaqsRoutes";
import WishListRoutes from "./wishListRoutes/WishListRoutes";
import StripeRoutes from "./stripeRoutes/StripeRoutes";
import AdvertisementsRoutes from "./advertisementsRoutes/AdvertisementsRoutes";
import HomeIndex from "../web/pages/home/HomeIndex";
import ShoppingCartRoutes from "./shoppingCartRoutes/ShoppingCartRoutes";
import Layout from "../admin/Layout";
import NotificationList from "../web/pages/notificationList/NotificationList";
import { useAuth } from "../context/AuthContext";
import Profile from "../web/components/user/Profile";
import AboutUs from "../web/pages/about/About";
import ChatRoutes from "./chatRoutes/ChatRoutes";
import BuyerOrders from "../web/pages/buyerOrders/BuyerOrders";
import Login from "../web/components/user/Login";

const IndexRouter = () => {
  const auth = useAuth();
  const token = auth?.user?.token;
  const user = auth?.user?.user;
  return (
    <Routes>
      <Route
        path="/dashboard/*"
        element={
          user?.verified || user?.is_staff ? <Layout /> : <Navigate to="/" />
        }
      />
      <Route path="/" element={<App />}>
        <Route path="/" element={<HomeIndex />} />
        <Route path="/*" element={<AuthRoutes />} />

        <Route path="/contact/*" element={<ContactRoutes />} />
        <Route path="/about/*" element={<AboutUs />} />
        <Route path="/products/*" element={<ProductsRoutes />} />
        <Route path="/shopping-cart/*" element={<ShoppingCartRoutes />} />
        <Route path="/faq/*" element={<FaqsRoutes />} />
        <Route path="/wish-list/*" element={<WishListRoutes />} />
        <Route path="/profile" element={token ? <Profile /> : <HomeIndex />} />
        <Route path="/buyer-orders/" element={<BuyerOrders />} />
        <Route path="/notification/" element={<NotificationList />} />
        <Route path="/stripe-payment/*" element={<StripeRoutes />} />
        <Route path="/advertisements/*" element={<AdvertisementsRoutes />} />
        <Route path="/chat/*" element={token ? <ChatRoutes /> : <Login />} />
      </Route>
    </Routes>
  );
};

export default IndexRouter;
