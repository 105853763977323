// Move all imports to the top of the file
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  InputBase,
} from "@mui/material";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import useDelete from "../../../api/useDelete";
import useUpdate from "../../../api/useUpdate";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import {
  Add,
  ArrowBack,
  ArrowForward,
  SearchOutlined,
  TextFieldsOutlined,
} from "@mui/icons-material";
import { useAuth } from "../../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import useAdd from "../../../api/useAdd";
import { useTranslation } from "react-i18next";
import EditProductDialog from "./EditProductDialog";

const Products = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const [filter, setFilter] = useState("");
  const [offerOpen, setOfferOpen] = useState(false);
  const [value, setValue] = useState();
  const auth = useAuth();
  const user = auth?.user;
  const token = auth?.user?.token;
  const navigate = useNavigate();
  const [product, setProduct] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState(4);
  const [heroImage, setHeroImage] = useState(null);
  const [heroImagePreview, setHeroImagePreview] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [searchType, setSearchType] = useState("search");
  const [category, setCategory] = useState("");
  const location = useLocation();
  const [userId, setUserId] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [search, setSearch] = useState("");
  const [openEdit, setOpenEdit] = useState(null);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["ProductsList", page],
    `products/?${searchType}=${search}&page=${
      page + 1
    }&page_size=${pageSize}&category=${category}&user=${userId}`,
    token
  );

  const {
    data: users,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    refetch: refetchUsers,
  } = useFetchObjects(["UsersList"], `admin/users/`, token);

  const { handleUpdate, Buttons, loading } = useUpdate(
    `products`,
    token,
    "/dashboard/products"
  );

  const {
    handleAdd: handleAddFeaturedProduct,
    loading: featuredProductLoading,
  } = useAdd("featured-products", token);

  const { handleAdd: handleAddFeatureBrand, loading: featuredBrandLoading } =
    useAdd("featured-brands", token);

  const { handleAdd: handleAddSpecialOffer, loading: specialOfferLoading } =
    useAdd("special-offer", token);

  const {
    handleAdd: handleAddHero,
    resetForm,
    loading: heroLoading,
  } = useAdd("hero", token);

  useEffect(() => {
    if (location.state) {
      setUserId(location?.state?.userInfo?.id);
      setUserInfo(location?.state?.userInfo);
    } else {
      setUserId("");
    }
  }, [location]);

  useEffect(() => {
    if (resetForm) {
      setImageError(null);
      setHeroImage("");
      setCurrentProduct("");
    }
  }, [resetForm]);

  useEffect(() => {
    refetch();
  }, [filter, refetch, search, page, pageSize, searchType, category, userId]);

  const [homePageData, setHomePageData] = useState({
    product: "",
    time: "",
  });

  const columns = [
    { field: "id", headerName: t("manage_product.id"), flex: 0.5 },
    {
      field: "images",
      headerName: t("manage_product.photo"),
      align: "center",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <img
            src={params.value[0]?.image}
            style={{ width: "5rem", height: "auto", padding: "4px" }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: t("manage_product.productName"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "category",
      headerName: t("manage_product.category"),
      headerAlign: "left",
      renderCell: (params) => <div>{params?.row?.display_category}</div>,
      align: "left",
    },
    {
      field: "price",
      headerName: t("manage_product.price"),
      renderCell: (params) => (
        <div>
          {params?.row?.currency === 1
            ? `${params?.value} ؋`
            : `${params?.value} $`}
        </div>
      ),
      flex: 1,
    },

    {
      field: "quantity",
      headerName: t("manage_product.quantity"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("manage_product.created"),
      flex: 1,
      renderCell: (params) => (
        <div>
          <span>{params.row.created_at.split("T")[0]}</span>
        </div>
      ),
    },
    {
      field: "discount",
      headerName: t("manage_product.discount"),
      renderCell: (params) => (
        <div>
          <span>{params.value}</span>
          <span>%</span>
        </div>
      ),
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("manage_product.actions"),
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setProduct(params.row);
              setOpenEdit(true);
            }}
          >
            {t("manage_product.edit")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
            style={{ margin: "0em 1em" }}
          >
            {t("manage_product.delete")}
          </Button>
          <IconButton onClick={() => handleAddToHomePage(params.row.id)}>
            <Add />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const {
    data: categories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    refetch: refetchCategories,
  } = useFetchObjects(["CategoriesList"], `categories/`, token);

  const { handleDelete, ConfirmDialog } = useDelete(`products`, token);

  const handleEdit = (row) => {
    setOpen(true);
    setCurrentProduct(row);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleAddToHomePage = (productId) => {
    setOfferOpen(true);
    setCurrentProduct(productId);
    setHomePageData({
      product: productId,
      time: homePageData.time,
    });
  };

  const handleChangeOfferTime = (e) => {
    setHomePageData({ ...homePageData, time: e.target.value });
  };

  const handleSubmitHeroData = () => {
    if (!heroImage) {
      setImageError(t("manage_product.imageRequired"));
      return;
    }
    if (!currentProduct) {
      setImageError(t("manage_product.productRequired"));
      return;
    }

    const submitData = new FormData();

    submitData.append("product", currentProduct);
    submitData.append("image", heroImage);

    handleAddHero(submitData);
  };

  if (isError || isErrorCategories || isErrorUser) {
    return <ErrorPage />;
  }

  return (
    <Box m="20px" mt="0">
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Header
            title={t("manage_product.product1")}
            subtitle={
              userInfo
                ? `${t("manage_product.product")} ${userInfo?.username}`
                : t("manage_product.listAllProduct")
            }
          />
          {userInfo && (
            <Button
              variant="outlined"
              color="secondary"
              sx={{ textTransform: "capitalize", mt: 2, mr: 1 }}
              onClick={() => {
                setUserId("");
                setUserInfo("");
              }}
            >
              {t("manage_product.seeAllProduct")}
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"cneter"}
          >
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="searchType"
                onChange={(e) => {
                  setSearchType(e.target.value);
                }}
                size="small"
                label={t("manage_product.searchType")}
                select
              >
                <MenuItem value={"id"}>{t("manage_product.id")}</MenuItem>
                <MenuItem value={"search"}>{t("manage_product.name")}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={9}>
              <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
                gap={1}
              >
                <InputBase
                  onChange={handleSearchChange}
                  sx={{ m: "0em 1em", flex: 1 }}
                  placeholder={t("manage_product.search")}
                />
                <IconButton type="button" sx={{ p: 1 }}>
                  <SearchOutlined />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label={t("manage_product.filterByCategory")}
              name="category"
              size="small"
              select
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              style={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#272a40" : "#f2f3f5", // Dark mode: #424242, Light mode: #f5f5f5
                borderRadius: "4px", // Optional: Add border-radius for rounded corners
              }}
              InputLabelProps={{
                style: {
                  color: theme.palette.mode === "dark" ? "white" : "gray", // Change label text color based on theme
                },
              }}
            >
              {categories?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Box
        m="40px 0 0 0"
        mt={0}
        height="72vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          t("manage_product.loading")
        ) : (
          <DataGrid
            rows={data?.results && data?.results}
            columns={columns}
            rowHeight={80}
            components={{ Toolbar: GridToolbar }}
            pagination
            pageSize={pageSize}
            rowCount={data?.count}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            loading={loading}
            sx={{ mt: 1 }}
          />
        )}
      </Box>

      <Dialog
        open={offerOpen}
        onClose={() => {
          setOfferOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <form encType="multipart/form-data">
          <DialogTitle
            style={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            <Typography variant="h5">
              {t("manage_product.specialOffer")}
            </Typography>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <br /> <br />
            <Grid spacing={3} container justifyContent={"center"}>
              {value === 4 ? (
                <>
                  <Stack alignItems="center">
                    <input
                      id="image-input"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => {
                        setHeroImagePreview(
                          URL.createObjectURL(e.target.files[0])
                        );
                        setHeroImage(e.target.files[0]);
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="image-input">
                      <Button
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        component="span"
                        color="secondary"
                      >
                        {t("manage_product.uploadHeroImage")}
                      </Button>
                    </label>
                    {heroImage && (
                      <>
                        <Box
                          component={"img"}
                          src={heroImagePreview}
                          sx={{
                            width: "10vw",
                            height: "14vh",
                            borderRadius: "10px",
                            mt: 2,
                          }}
                        />

                        <Button
                          variant="contained"
                          sx={{ textTransform: "capitalize", mt: 2 }}
                          component="span"
                          color="secondary"
                          onClick={handleSubmitHeroData}
                        >
                          {t("manage_product.submitHeroData")}
                        </Button>
                      </>
                    )}
                  </Stack>
                </>
              ) : (
                <Grid xs={12} md={6} sm={6} item>
                  <TextField
                    fullWidth
                    autoFocus
                    size="small"
                    label={t("manage_product.dateTime")}
                    name="time"
                    type="datetime-local"
                    value={homePageData.time}
                    onChange={handleChangeOfferTime}
                    InputLabelProps={{
                      shrink: true, // Ensures the label stays shrunk when there is a value
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Grid container justifyContent={"space-between"} mt={2}>
              <Button
                onClick={() => setOfferOpen(false)}
                color="primary"
                variant="contained"
              >
                {t("manage_product.cancel")}
              </Button>
              <Button
                variant="contained"
                color={value === 1 ? "secondary" : "primary"}
                disabled={value === 1}
                onClick={() => {
                  setValue(1);
                  handleAddSpecialOffer(homePageData);
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {t("manage_product.specialOffer1")}
              </Button>

              <Button
                variant="contained"
                color={value === 2 ? "secondary" : "primary"}
                disabled={value === 2}
                onClick={() => {
                  setValue(2);
                  handleAddFeatureBrand(homePageData);
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {t("manage_product.featuredBrands")}
              </Button>

              <Button
                variant="contained"
                color={value === 3 ? "secondary" : "primary"}
                onClick={() => {
                  setValue(3);
                  handleAddFeaturedProduct(homePageData);
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {t("manage_product.featuredProducts")}
              </Button>

              <Button
                variant="contained"
                color={value === 4 ? "secondary" : "primary"}
                onClick={() => {
                  setValue(4);
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {t("manage_product.hero")}
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmDialog />

      {openEdit && (
        <EditProductDialog
          product={product}
          open={openEdit}
          onClose={() => setOpenEdit(false)}
        />
      )}
    </Box>
  );
};

export default Products;
