import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  IconButton,
  InputBase,
} from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../context/AuthContext";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info"; // Importing an icon for better UX
import { SearchOutlined } from "@mui/icons-material";

const Logs = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useAuth();
  const token = auth?.user?.token;
  const [search, setSearch] = useState("");

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["audit-logs"],
    `audit-logs/?search=${search}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [search]);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Box
      m={"5px"}
      sx={{
        backgroundColor: colors.primary[500],
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Header title={t("logs.title", "User Logs")} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <InputBase
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              sx={{ m: "0em 1em", flex: 1 }}
              placeholder={t("payment.search")}
            />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchOutlined />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Box m="20px 0 0 0" sx={{ maxHeight: 580, overflowY: "auto" }}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="60vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          data?.map((log) => (
            <Card
              key={log.id}
              sx={{
                marginBottom: 2,
                backgroundColor: colors.primary[400],
                boxShadow: 3,
              }}
            >
              <CardHeader
                title={`${t("logs.user", "User")}: ${log.user}`}
                subheader={` ${new Date(log.timestamp).toLocaleString()}`}
                sx={{ backgroundColor: colors.blueAccent[700], color: "#fff" }}
              />
              <CardContent>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {t("logs.action", "Action")}: {log.action_display}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ marginTop: 2, fontWeight: "bold" }}
                >
                  {t("logs.changedFields", "Changed Fields")}:
                </Typography>

                <List>
                  {Object.entries(log.changed_fields).map(
                    ([field, { old, new: newValue }]) => (
                      <ListItem
                        key={field}
                        sx={{
                          "&:hover": {
                            backgroundColor: colors.blueAccent[500],
                          },
                        }}
                      >
                        <IconButton
                          edge="start"
                          aria-label="info"
                          size="small"
                          sx={{ color: colors.grey[100] }}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                        <ListItemText
                          primary={field}
                          secondary={`Old: ${
                            old !== null ? old : "None"
                          } | New: ${newValue !== null ? newValue : "None"}`}
                        />
                      </ListItem>
                    )
                  )}
                </List>

                <Divider sx={{ margin: "10px 0" }} />

                <Typography variant="body2">
                  {t("logs.changedBy", "Changed By")}: {log.changed_by}
                </Typography>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    </Box>
  );
};

export default Logs;
