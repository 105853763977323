import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Input,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { CloudUpload } from "@mui/icons-material";
import useAdd from "../../../api/useAdd";
import useFetchObjects from "../../../api/useFetchObjects";

const AddPost = ({ open, onClose }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  // Internal state (no validations)
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    link: "",
    image: null,
    product: null,
  });

  const [formErrors, setFormErrors] = useState({
    title: "",
  });

  const [fileInfo, setFileInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["Products"],
    `products`,
    token
  );
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);


      setTimeout(() => {
        setFileInfo({
          file: file,
          name: file.name,
        });
        setLoading(false);
      }, 1000);
    }
  };

  const {
    handleAdd,
    resetForm,
    loading: loadingUpload,
  } = useAdd(
    "posts",
    token,
    "Advertisement added successfully",
    "Failed to add Advertisement"
  );

  // Update form fields when inputs change.
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};

    // Title validation
    if (!formData.title.trim()) {
      errors.title = "Title is required.";
    }

    setFormErrors(errors);

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };

  // Submission handler: build a FormData payload and update the ad.
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form
    if (!validateForm()) {
      return; // Stop submission if there are validation errors
    }
    try {
      fileInfo.file && setFormData({ ...formData, image: fileInfo?.file })

      const sendData = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null) {
          sendData.append(key, value);
        }
      });

      handleAdd(sendData);
      onClose()
    } catch (error) {
      alert("Failed to submit the form. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DialogTitle
          sx={{
            textAlign: "center",
            backgroundColor: "#3f51b5",
            color: "#fff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">{t("manage_product.editad")}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ padding: 2, maxHeight: "65vh", overflowY: "auto" }}
        >
          <Grid mt={1} container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                error={!!formErrors.title}
                helperText={formErrors.title}
              />
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={t("manage_product.description")}
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
            {/* Link */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Link"
                name="link"
                value={formData.link}
                onChange={handleChange}
                error={!!formErrors.link}
                helperText={formErrors.link}
              />
            </Grid>
            {/* Products */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Product"
                name="product"
                value={formData.product}
                onChange={handleChange}
                select
              >
                {data?.results && data?.results?.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 4,
                  maxWidth: 500,
                  margin: "auto",
                  width: "100%",
                  textAlign: "center",
                  boxShadow: 3,
                  borderRadius: 3,
                  backgroundColor: "background.paper",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Upload Your File
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginBottom: 2 }}
                >
                  Select an image or video file to upload.
                </Typography>
                <Box sx={{ marginBottom: 2 }}>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                    sx={{
                      backgroundColor: "primary.main",
                      "&:hover": { backgroundColor: "primary.dark" },
                    }}
                  >
                    Choose File
                    <Input
                      type="file"
                      onChange={handleFileChange}
                      inputProps={{ accept: "image/*,video/*" }}
                      sx={{ display: "none" }}
                    />
                  </Button>
                </Box>

                {loading && (
                  <Box sx={{ width: "100%", marginBottom: 2 }}>
                    <LinearProgress />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ marginTop: 1 }}
                    >
                      Uploading...
                    </Typography>
                  </Box>
                )}

                {fileInfo && !loading && (
                  <Typography variant="subtitle1" color="text.primary">
                    <strong>File Name:</strong> {fileInfo.name}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ textTransform: "capitalize" }}
          >
            {t("manage_product.cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ textTransform: "capitalize" }}
          >
            {t("manage_product.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPost;
