import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Badge,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import useFetchObjects from "../../../api/useFetchObjects";
import useUpdate from "../../../api/useUpdate";
import Context from "../../../context/Context";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Search from "./Search";
import LanguageSelector from "../../../lang/LanguageSelector";
import { useTranslation } from "react-i18next";
import MessageIcon from "@mui/icons-material/Message";

const iconStyle = {
  color: "#ffffff", // White for professionalism to match the dark background
  ":hover": {
    cursor: "pointer",
    color: "#FFB400", // Hover effect matches subscribe button color
  },
};

const navLinkStyle = {
  textTransform: "capitalize",
  fontSize: "1rem",
  fontWeight: 600,
  padding: "5px 10px",
  margin: "0 5px",
  borderRadius: "4px",
  textDecoration: "none",
  fontFamily: "'Roboto', sans-serif",

  backgroundColor: "#2E2E2E", // Inactive state background matches footer
  color: "#bdbdbd", // Inactive state text color similar to footer
  "&:hover": {
    backgroundColor: "#444", // Slight hover effect for inactive links
  },
};

const navLinkActivekStyle = {
  textTransform: "capitalize",
  fontSize: "1rem",
  fontWeight: 600,
  padding: "5px 10px",
  margin: "0 5px",
  borderRadius: "4px",
  textDecoration: "none",
  fontFamily: "'Roboto', sans-serif",
  backgroundColor: "#FFB400", // Active state background matches subscribe button
  color: "#000000", // Active state text color matches subscribe button
};

const NavBar = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const auth = useAuth();
  const user = auth?.user;
  const userID = user?.user?.id;
  const token = user?.token;

  const { wishlistItems, cartItems } = useContext(Context);
  const navigate = useNavigate();

  const { data: unreadMessageCount } = useFetchObjects(
    ["un-read-message-count"],
    `un-read-message-count/${userID || 0}/`
  );

  const { data: totalNotification, isError } = useFetchObjects(
    ["notification"],
    `unread-order-count/?user_id=${userID || 0}`
  );

  const openProfile = Boolean(anchorEl);

  const handleOpenProfile = (event) => {
    setAnchorEl(event.currentTarget); // Ensure the correct anchor element
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickDashboard = () => {
    navigate("/dashboard");
  };

  const drawer = (
    <Box sx={{ width: 250, padding: "20px 10px" }} onClick={handleDrawerToggle}>
      <Typography
        variant="h6"
        sx={{ marginBottom: "10px", fontWeight: "bold" }}
      >
        {t("navbar.menu")}
      </Typography>
      <List>
        <ListItem button component={NavLink} to="/" exact>
          <ListItemText primary={t("navbar.home")} />
        </ListItem>
        <ListItem button component={NavLink} to="/products">
          <ListItemText primary={t("navbar.product")} />
        </ListItem>
        <ListItem button component={NavLink} to="/faq">
          <ListItemText primary={t("navbar.faq")} />
        </ListItem>
        <ListItem button component={NavLink} to="/contact">
          <ListItemText primary={t("navbar.contactUs")} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Toolbar>
      <Grid container alignItems="center" justifyContent="space-between">
        {/* Logo and Mobile Menu Icon */}
        <Grid item xs={2} md={1} display="flex">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mt: 1, display: { xs: "block", lg: "none" } }}
          >
            <MenuIcon sx={{ color: "#FFB400", mb: 1 }} />
          </IconButton>
          <Box
            component="img"
            src={`${process.env.REACT_APP_IMAGE_URL}images/logo.png`}
            alt="logo"
            sx={{
              width: { xs: "45px", md: "60px" },
              height: { xs: "45px", md: "60px" },
              padding: "5px",
              cursor: "pointer",
              mt: 1.4,
            }}
            onClick={() => navigate("/")}
          />
        </Grid>

        {/* Tabs for Desktop */}
        <Grid item sx={{ display: { xs: "none", lg: "block" } }} md={2} lg={5}>
          <Box display="flex" justifyContent="center" alignItems={"center"}>
            <NavLink
              to="/"
              exact
              style={({ isActive }) =>
                isActive ? navLinkActivekStyle : navLinkStyle
              }
            >
              {t("navbar.home")}
            </NavLink>
            <NavLink
              to="/products"
              style={({ isActive }) =>
                isActive ? navLinkActivekStyle : navLinkStyle
              }
            >
              {t("navbar.product")}
            </NavLink>
            <NavLink
              to="/faq"
              style={({ isActive }) =>
                isActive ? navLinkActivekStyle : navLinkStyle
              }
            >
              {t("navbar.faq")}
            </NavLink>
            <NavLink
              to="/contact"
              style={({ isActive }) =>
                isActive ? navLinkActivekStyle : navLinkStyle
              }
            >
              {t("navbar.contactUs")}
            </NavLink>
            <NavLink
              to="/about"
              style={({ isActive }) =>
                isActive ? navLinkActivekStyle : navLinkStyle
              }
            >
              {t("navbar.about")}
            </NavLink>
          </Box>
        </Grid>

        <Grid
          item
          xs={5}
          sm={5}
          md={6}
          lg={3}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Search />
        </Grid>

        {/* Icons + Profile */}
        <Grid
          item
          xs={9}
          sm={4.2}
          md={3}
          lg={2}
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Badge
            badgeContent={unreadMessageCount?.unread_count}
            color="error"
            onClick={() => navigate("/chat")}
          >
            <MessageIcon sx={iconStyle} />
          </Badge>
          <Badge
            badgeContent={wishlistItems.length}
            color="error"
            onClick={() => navigate("/wish-list")}
          >
            <FavoriteIcon sx={iconStyle} />
          </Badge>

          <Badge
            badgeContent={cartItems.length}
            color="error"
            onClick={() => navigate("/shopping-cart")}
          >
            <ShoppingCartOutlined sx={iconStyle} />
          </Badge>
          {(user?.user.verified || user?.user?.is_staff) && (
            <Badge
              badgeContent={totalNotification?.unread_count}
              color="error"
              onClick={() => {
                navigate("/notification");
              }}
            >
              <NotificationsIcon sx={iconStyle} />
            </Badge>
          )}
          <LanguageSelector />

          {/* Profile / Login Button */}
          {user?.user ? (
            <Avatar
              sx={{
                bgcolor: "#FFB400", // Matching with subscribe button color
                cursor: "pointer",
              }}
              onClick={handleOpenProfile}
            >
              {user?.user?.username?.slice(0, 1).toUpperCase()}
            </Avatar>
          ) : (
            <Button
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                borderColor: "#FFB400",
                color: "#FFB400",
                ":hover": {
                  borderColor: "#FFB400",
                  backgroundColor: "#ffc833",
                  color: "black",
                },
              }}
              onClick={() => {
                handleCloseProfile();
                navigate("/login");
              }}
            >
              {t("navbar.login")}
            </Button>
          )}
        </Grid>
      </Grid>

      {/* Profile Menu */}
      <Menu
        open={Boolean(anchorEl)} // Ensure menu opens only when anchorEl is set
        anchorEl={anchorEl}
        onClose={handleCloseProfile}
        id="profile-menu"
        anchorOrigin={{
          vertical: "bottom", // Open below the anchor
          horizontal: "right", // Align to the right of the anchor
        }}
        transformOrigin={{
          vertical: "top", // Align the top of the menu
          horizontal: "right", // Align menu’s right edge to the anchor
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/profile");
            handleCloseProfile();
          }}
        >
          {t("navbar.profile")}
        </MenuItem>
        {user?.user?.is_staff || user?.user?.verified ? (
          <MenuItem onClick={handleClickDashboard}>
            {t("navbar.dashboard")}
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            handleCloseProfile();
            auth.logout();
            navigate("/login");
          }}
        >
          {t("navbar.logout")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseProfile();
            navigate("/buyer-orders");
          }}
        >
          {t("navbar.yourOrders")}
        </MenuItem>
      </Menu>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: "block", lg: "none" },
        }}
      >
        {drawer}
      </Drawer>
    </Toolbar>
  );
};

export default NavBar;
