// src/pages/dashboard/categories/SubCategories.jsx
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, InputBase, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@mui/icons-material";

import Add from "./Add";
import Edit from "./Edit";
import { tokens } from "../../../theme";
import { useAuth } from "../../../../context/AuthContext";
import useFetchObjects from "../../../../api/useFetchObjects";
import useDelete from "../../../../api/useDelete";
import ErrorPage from "../../dashboard/ErrorPage";
import Header from "../../../components/Header";

const SubCategories = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useAuth();
  const token = auth?.user?.token;
  const [search, setSearch] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState(null);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["sub-categories"],
    `sub-category/?search=${search}`,
    token
  );

  const { handleDelete, ConfirmDialog } = useDelete("sub-category", token);

  useEffect(() => {
    refetch();
  }, [search, refetch]);

  const rows =
    data?.map((item) => ({
      id: item?.id,
      main_category: item?.main_category_details?.name || "",
      title: item?.title || "",
      description: item?.description || "",
    })) || [];

  const columns = [
    { field: "id", headerName: t("categories.id"), flex: 0.5 },
    {
      field: "main_category",
      headerName: t("categories.mainCategory"),
      flex: 1,
    },
    {
      field: "title",
      headerName: t("categories.title"),
      flex: 1,
    },
    {
      field: "description",
      headerName: t("categories.description"),
      flex: 2,
    },
    {
      field: "actions",
      headerName: t("categories.actions"),
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setEditData(params.row);
              setOpenEdit(true);
            }}
          >
            {t("categories.edit")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.id)}
          >
            {t("categories.delete")}
          </Button>
        </Stack>
      ),
    },
  ];

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Box m={"20px"}>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={12} md={6}>
          <Header title={t("categories.subCategory", "Sub Categories")} />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
            p={1}
          >
            <InputBase
              onChange={(e) => setSearch(e.target.value)}
              sx={{ ml: 1, flex: 1 }}
              placeholder={t("categories.search", "Search Sub Categories")}
            />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchOutlined />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} textAlign={"end"}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ textTransform: "capitalize", mr: 2 }}
            onClick={() => setOpenAdd(true)}
          >
            {t("categories.add", "Add Sub Category")}
          </Button>
        </Grid>
      </Grid>
      <Box
        m="20px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          rowHeight={60}
          components={{ Toolbar: GridToolbar }}
          loading={isLoading}
          getRowId={(row) => row.id}
          sx={{ mt: 1 }}
        />
        <ConfirmDialog />
      </Box>

      {openAdd && (
        <Add
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          token={token}
          onSuccess={() => {
            setOpenAdd(false);
            refetch();
          }}
        />
      )}

      {openEdit && (
        <Edit
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          token={token}
          editData={editData}
          onSuccess={() => {
            setOpenEdit(false);
            refetch();
          }}
        />
      )}
    </Box>
  );
};

export default SubCategories;
