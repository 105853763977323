import React from "react";
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context/AuthContext";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
} from "@mui/material";
import SkeletonLoading from "../../../components/skeletonLoading/SkeletonLoading";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import NoData from "../../../components/noData/NoData";
import DateFormatter from "../../../components/DateFormatter/DateFormatter";
import useDelete from "../../../api/useDelete";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const getStatusStep = (status) => {
  switch (status) {
    case 1: // Pending
      return 0;
    case 3: // Accepted
      return 1;
    case 4: // In Progress
      return 2;
    case 5: // Delivered
      return 3;
    default:
      return -1; // Invalid or canceled status
  }
};

const BuyerOrders = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const location = useLocation();
  const orderID = location?.state?.id;

  const { data, isError, isLoading } = useFetchObjects(
    ["orders"],
    `orders/?user=${user?.user?.id}&id=${orderID || ""}`,
    token
  );
  const { handleDelete, ConfirmDialog } = useDelete("orders", token);

  const steps = [
    {
      label: t("order.orderPlaced"),
      description: t("order.placedMessage"),
    },
    {
      label: t("order.accepted"),
      description: t("order.acceptedMessage"),
    },
    {
      label: t("order.progress"),
      description: t("order.progressMessage"),
    },
    {
      label: t("order.delivered"),
      description: t("order.deliveredMessage"),
    },
  ];

  if (isLoading) {
    return (
      <Grid container mt={1} padding={"20px"} spacing={2}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <SkeletonLoading />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (isError || !data) {
    return (
      <Grid container>
        <ErrorPage />
      </Grid>
    );
  }

  return (
    <Container>
      <Box m={4}>
        <Typography variant="h1" mb={4} textAlign="center">
          {t("order.yourOrder")}
        </Typography>

        {(!data || data?.length === 0) && (
          <Grid container>
            <NoData message={t("order.dontOrder")} />
          </Grid>
        )}
        <Grid container spacing={4}>
          {data.map((order) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={order.id}>
              <Card
                sx={{
                  borderRadius: "10px",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  },
                  minHeight: 470,
                }}
              >
                <CardContent>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {t("order.orderId")}: {order.id}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => {
                        handleDelete(order.id);
                      }}
                    >
                      {t("order.delete")}
                    </Button>
                  </Box>

                  <Typography variant="body2" color="text.secondary" mb={1}>
                    {t("order.date")}:{" "}
                    {DateFormatter(null, order?.order_date, order?.order_time)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mb={1}>
                    {t("order.totalAmount1")}: {order.total_amount_afg || "N/A"}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mb={2}>
                    {t("order.totalAmount2")}: {order.total_amount_usd || "N/A"}
                  </Typography>

                  {order.status === 2 ? (
                    <Typography variant="body1" color="error">
                      {t("order.canceled")}
                    </Typography>
                  ) : (
                    <Stepper
                      activeStep={getStatusStep(order.status)}
                      orientation="vertical"
                    >
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel>{step.label}</StepLabel>
                          <StepContent>
                            <Typography>{step.description}</Typography>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  )}
                </CardContent>
              </Card>
              <ConfirmDialog />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default BuyerOrders;
