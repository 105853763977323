import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Users from "./scenes/users";
import Products from "./scenes/products";
import Categories from "./scenes/categories";
import Orders from "./scenes/orders";
import React from "react";
import SellerProducts from "./scenes/sellerDashboard/products";
import SellerOrders from "./scenes/sellerDashboard/orders";
import AddProduct from "./scenes/sellerDashboard/addProduct";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Details from "./scenes/users/Details";
import Hero from "./scenes/home/Hero";
import Index from "./scenes/home/Index";

import FeaturedProducts from "./scenes/home/FeaturedProducts";
import SpecialOffer from "./scenes/home/SpecialOffer";
import FeaturedBrands from "./scenes/home/FeaturedBrands";
import PaymentList from "./scenes/payments/PaymentList";
import Contact from "./scenes/contact/Contact";
import { useAuth } from "../context/AuthContext";
import Advertisements from "./scenes/advertisements";

import Posts from "./scenes/posts";

import DeliveryCost from "./scenes/deliveryCost/DeliveryCost";
import Logs from "./scenes/users/Logs";
import SubCategories from "./scenes/categories/SubCategory/SubCategories";
import Notifications from "./scenes/notifications/Notifications";


function Layout() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const auth = useAuth();
  const user = auth?.user?.user;
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              {user?.is_staff && <Route path="/" element={<Dashboard />} />}
              <Route path="/users" element={<Users />} />
              <Route path="/detail/:id" element={<Details />} />

              {/* Products */}
              <Route path="/products" element={<Products />} />
              <Route path="/seller/add_product" element={<AddProduct />} />
              <Route path="/seller/products" element={<SellerProducts />} />

              {/* Orders */}
              <Route path="/seller/orders" element={<SellerOrders />} />
              <Route path="/orders" element={<Orders />} />

              {/* Categories */}
              <Route path="/categories" element={<Categories />} />
              <Route path="/sub-categories" element={<SubCategories />} />

              <Route path="/payments" element={<PaymentList />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/advertisements" element={<Advertisements />} />

              <Route path="/posts" element={<Posts />} />

              <Route path="/delivery-cost" element={<DeliveryCost />} />
              <Route path="/user-logs" element={<Logs />} />


              {/* Notifications */}
              <Route path="/notifications" element={<Notifications />} />
              {/* Home pages */}
              <Route path="/home" element={<Index />}>
                <Route path="/home" element={<Hero />} />
                <Route path="/home/special-offer" element={<SpecialOffer />} />
                <Route
                  path="/home/featured-brands"
                  element={<FeaturedBrands />}
                />
                <Route
                  path="/home/featured-products"
                  element={<FeaturedProducts />}
                />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Layout;
