import React from "react";
import { Route, Routes } from "react-router-dom";
import Advertisements from "../../admin/scenes/advertisements";

const AdvertisementsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Advertisements />} />
      {/* <Route path="/:id" element={<ProductDetails />} /> */}
    </Routes>
  );
};

export default AdvertisementsRoutes;
