import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import useFetchObjects from "../../../api/useFetchObjects";
import useDelete from "../../../api/useDelete";
import useUpdate from "../../../api/useUpdate";
import { useAuth } from "../../../context/AuthContext";
import { SearchOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Users = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [seller, setSeller] = useState("true");
  const [userID, setUserID] = useState("");
  const [search, setSearch] = useState("");
  const [verifyDate, setVerifyDate] = useState("");
  const [description, setDescription] = useState("");
  const [verified, setVerified] = useState("");
  const [value, setValue] = useState("all");
  const auth = useAuth();
  const user = auth?.user;
  const token = auth?.user?.token;

  const navigate = useNavigate();

  const { handleUpdate, loading } = useUpdate(
    `admin/users`,
    token,
    "/dashboard/users"
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const submitData = new FormData();

    submitData.append("verified", true);
    if (verifyDate) {
      submitData.append("temporary_verification_expiration", verifyDate);
      submitData.append("description", description);
    }

    handleUpdate(userID, submitData);
    setOpen(false);
  };

  const columns = [
    { field: "id", headerName: t("manage_user.id") },
    {
      field: "username",
      headerName: t("manage_user.username"),
      flex: 1,
    },
    {
      field: "first_name",
      headerName: t("manage_user.firstName"),
      flex: 1,
    },
    {
      field: "last_name",
      headerName: t("manage_user.lastName"),
      flex: 1,
    },
    {
      field: "phone_number",
      headerName: t("manage_user.phoneNumber"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("manage_user.email"),
      flex: 1,
    },
    {
      field: "is_seller",
      headerName: t("manage_user.accountType"),

      flex: 1,
      renderCell: ({ row: { is_seller } }) => {
        return (
          <Typography>
            {is_seller ? t("manage_user.seller") : t("manage_user.buyer")}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: t("manage_user.actions"),
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
            style={{ textTransform: "capitalize" }}
          >
            {t("manage_user.delete")}
          </Button>

          {params.row.verified && (
            <Button
              variant="contained"
              style={{
                backgroundColor: "green",
                textTransform: "capitalize",
                margin: "0em 0.5em",
              }}
              onClick={() => handleUpdate(params.row.id, { verified: false })} // Unverify action
            >
              {t("manage_user.unverify")}
            </Button>
          )}

          {params.row.is_seller === true && (
            <>
              {!params.row.verified && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setUserID(params.row.id);
                    setOpen(true);
                  }} // Verify action
                  sx={{ textTransform: "capitalize" }}
                >
                  {t("manage_user.verify")}
                </Button>
              )}
            </>
          )}
        </Stack>
      ),
    },
    {
      field: "details",
      headerName: t("manage_user.details"),
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log(params);
              navigate(`/dashboard/detail/${params.row.id}`);
            }}
            sx={{ textTransform: "capitalize" }}
          >
            {t("manage_user.details")}
          </Button>
        );
      },
    },
    {
      field: "products",
      headerName: t("manage_user.products"),
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log(params);
              navigate(`/dashboard/products`, {
                state: { userInfo: params?.row },
              });
            }}
            sx={{ textTransform: "capitalize" }}
          >
            {t("manage_user.products")}
          </Button>
        );
      },
    },
  ];

  const [filter, setFilter] = useState("");
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["UsersList"],
    `admin/users/?search=${search}&is_seller=${seller}&verified=${verified}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [filter, refetch, search, seller, verified]);

  const { handleDelete, ConfirmDialog } = useDelete(`admin/users`, token);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Box m="20px">
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={6}>
          <Header
            title={t("manage_user.users")}
            subtitle={t("manage_user.listUsers")}
          />
        </Grid>
        <Grid item xs={6}>
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <InputBase
              onChange={handleSearchChange}
              sx={{ m: "0em  1em", flex: 1 }}
              placeholder={t("manage_user.search")}
            />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchOutlined />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid>
        <Button
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            bgcolor: value === "seller" ? "#0ac28e" : "#233654",
            color: "white",
            marginRight: "5px",
          }}
          onClick={() => {
            setValue("seller");
            setSeller("true");
          }}
        >
          {t("manage_user.sellers")}
        </Button>

        <Button
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            bgcolor: value === "verified" ? "#0ac28e" : "#233654",
            color: "white",
            marginRight: "5px",
          }}
          onClick={() => {
            setValue("verified");
            setVerified("true");
          }}
        >
          {t("manage_user.verified")}
        </Button>

        <Button
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            bgcolor: value === "unverified" ? "#0ac28e" : "#233654",
            color: "white",
            marginRight: "5px",
          }}
          onClick={() => {
            setValue("unverified");
            setVerified("false");
          }}
        >
          {t("manage_user.unverified")}
        </Button>

        <Button
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            bgcolor: value === "buyer" ? "#0ac28e" : "#233654",
            color: "white",
            marginRight: "5px",
          }}
          onClick={() => {
            setValue("buyer");
            setSeller("false");
          }}
        >
          {t("manage_user.buyers")}
        </Button>

        <Button
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            bgcolor: value === "all" ? "#0ac28e" : "#233654",
            color: "white",
            marginRight: "5px",
          }}
          onClick={() => {
            setValue("all");
            setSeller("");
            setVerified("");
          }}
        >
          {t("manage_user.all")}
        </Button>
      </Grid>
      <Box
        m="10px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          <Typography mt={2}>{t("manage_user.loading")}</Typography>
        ) : isError ? (
          <Alert severity="error">{t("manage_user.FieldLoading")}</Alert>
        ) : (
          <DataGrid
            rows={data && data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        )}
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            style={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            <Typography variant="h5">{t("manage_user.editProduct")}</Typography>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <Grid container mt={3} padding={"15px"} justifyContent={"center"}>
              <Grid item xs={12} md={8}>
                <FormLabel>{t("manage_user.wantVerify")}</FormLabel>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  name="temporary_verification_expiration"
                  type="date"
                  value={verifyDate}
                  onChange={(e) => {
                    setVerifyDate(e.target.value);
                  }}
                  sx={{ mt: 1 }}
                />
              </Grid>

              <Grid item xs={12} md={8} mt={2}>
                <FormLabel>{t("manage_user.whyVerify")}</FormLabel>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  name="description"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  sx={{ mt: 1 }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="contained"
            >
              {t("manage_user.cancel")}
            </Button>
            <Button type="submit" color="secondary" variant="contained">
              {t("manage_user.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmDialog />
    </Box>
  );
};

export default Users;
