import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Drawer,
  IconButton,
  ListSubheader,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Box,
  CircularProgress,
  Typography,
  Tab,
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useFetchObjects from "../../../api/useFetchObjects";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import { useLocation } from "react-router-dom";
import Context from "../../../context/Context";
import CardsSkeleton from "./loading/CardsSkeleton";
import NoData from "../../../components/noData/NoData";
import { useTranslation } from "react-i18next";
import SellerProfile from "../sellerProfile/SellerProfile";
import InfiniteScroll from "react-infinite-scroll-component";

// Lazy load the Cards component
const Cards = lazy(() => import("./Cards"));

const Products = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location || {};
  const userInfo = state?.userInfo || null;
  const userID = userInfo?.id || "";

  // Initialize state with persisted data or default values
  const [page, setPage] = useState(() => {
    const savedPage = localStorage.getItem("productsPage");
    return savedPage ? Number(savedPage) : 1;
  });
  const rowsPerPage = 6; // Fixed value, no need for state

  const [allProducts, setAllProducts] = useState(() => {
    const savedProducts = localStorage.getItem("productsList");
    return savedProducts ? JSON.parse(savedProducts) : [];
  });

  const [hasMore, setHasMore] = useState(() => {
    const savedHasMore = localStorage.getItem("productsHasMore");
    return savedHasMore ? JSON.parse(savedHasMore) : true;
  });

  const [noDataMessage, setNoDataMessage] = useState(() => {
    const savedMessage = localStorage.getItem("productsNoDataMessage");
    return savedMessage ? savedMessage : null;
  });

  const catID = state?.category || "";
  const [openList, setOpenList] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Initialize filters from localStorage or default
  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem("productsFilters");
    return savedFilters
      ? JSON.parse(savedFilters)
      : { color: [], size: [], category: [] };
  });

  const { searchTerm } = useContext(Context);

  const [parentCategoryFilter, setParentCategoryFilter] = useState(() => {
    const savedParentCategory = localStorage.getItem(
      "productsParentCategoryFilter"
    );
    return savedParentCategory ? savedParentCategory : "";
  });

  const color = filters.color.join(",");
  const size = filters.size.join(",");
  const category = filters.category.join(",");

  // Selected Tab index
  const [value, setValue] = useState(0);

  // Fetch Products
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["ProductsList", page, filters, searchTerm, userID, parentCategoryFilter],
    `products/?color=${color}&size=${size}&category=${
      category || catID
    }&search=${encodeURIComponent(searchTerm)}&user=${
      userID || ""
    }&user_verified=true&page=${page}&page_size=${rowsPerPage}&parent_category=${parentCategoryFilter}`
  );

  // Fetch Categories
  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
  } = useFetchObjects(["CategoriesList"], "categories/");

  // Fetch Parent Categories
  const {
    data: parentCategories,
    isLoading: isLoadingParentCategories,
    isError: isErrorParentCategories,
  } = useFetchObjects(["ParentCategoriesList"], "parent-category");

  // Save state to localStorage whenever relevant state variables change
  useEffect(() => {
    localStorage.setItem("productsPage", page);
    localStorage.setItem("productsList", JSON.stringify(allProducts));
    localStorage.setItem("productsHasMore", JSON.stringify(hasMore));
    localStorage.setItem("productsNoDataMessage", noDataMessage);
    localStorage.setItem("productsFilters", JSON.stringify(filters));
    localStorage.setItem("productsParentCategoryFilter", parentCategoryFilter);
  }, [
    page,
    allProducts,
    hasMore,
    noDataMessage,
    filters,
    parentCategoryFilter,
  ]);

  // Reset products and page when filters, searchTerm, userID, or parentCategoryFilter change
  useEffect(() => {
    // If filters, searchTerm, userID, or parentCategoryFilter change, reset products
    setAllProducts([]);
    setPage(1);
    setHasMore(true);
    setNoDataMessage(null);
  }, [filters, searchTerm, userID, parentCategoryFilter]);

  // Debounce refetch to prevent excessive calls
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm || filters || page) {
        refetch();
      }
    }, 500); // 500ms debounce

    return () => clearTimeout(timer);
  }, [filters, searchTerm, page, parentCategoryFilter, refetch]);

  // Append new products when data changes
  useEffect(() => {
    if (data && data.results) {
      setAllProducts((prevProducts) => {
        // Prevent duplicates by using product IDs
        const newProducts = data.results.filter(
          (product) => !prevProducts.some((p) => p.id === product.id)
        );
        const updatedProducts = [...prevProducts, ...newProducts];

        // Determine if there are more products to load
        if (updatedProducts.length >= data.count) {
          setHasMore(false);
          setNoDataMessage("There are no more products.");
        }

        return updatedProducts;
      });
    } else if (data && data.results.length === 0 && page === 1) {
      setNoDataMessage("No products found.");
      setHasMore(false);
    }
  }, [data, page]);

  // Set the selected tab index based on parentCategoryFilter
  useEffect(() => {
    if (parentCategories) {
      if (parentCategoryFilter === "") {
        setValue(0); // All tab
      } else {
        const index = parentCategories.findIndex(
          (cat) => String(cat.id) === String(parentCategoryFilter)
        );
        setValue(index !== -1 ? index + 1 : 0); // +1 because 0 is "All"
      }
    }
  }, [parentCategories, parentCategoryFilter]);

  // Handle Filter Changes
  const handleFilterChange = useCallback((event, filterType) => {
    const { value } = event.target;
    setFilters((prevFilters) => {
      let updatedFilter = [...prevFilters[filterType]];
      if (filterType === "category") {
        const numericValue = Number(value);
        if (updatedFilter.includes(numericValue)) {
          updatedFilter = updatedFilter.filter((item) => item !== numericValue);
        } else {
          updatedFilter.push(numericValue);
        }
      } else {
        if (updatedFilter.includes(value)) {
          updatedFilter = updatedFilter.filter((item) => item !== value);
        } else {
          updatedFilter.push(value);
        }
      }
      return { ...prevFilters, [filterType]: updatedFilter };
    });
  }, []);

  // Handle List Item Click (Expand/Collapse)
  const handleClick = useCallback((listItem) => {
    setOpenList((prevOpenList) => ({
      ...prevOpenList,
      [listItem]: !prevOpenList[listItem],
    }));
  }, []);

  // Toggle Drawer for Mobile
  const toggleDrawer = useCallback(
    (open) => () => {
      setDrawerOpen(open);
    },
    []
  );

  // Handle Tab Change
  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      if (newValue === 0) {
        setParentCategoryFilter("");
      } else {
        const selectedCategory = parentCategories[newValue - 1];
        setParentCategoryFilter(selectedCategory ? selectedCategory.id : "");
      }
    },
    [parentCategories]
  );

  // Define Colors and Sizes with translations
  const colors = [
    "red",
    "blue",
    "green",
    "yellow",
    "black",
    "white",
    "orange",
    "purple",
    "pink",
    "brown",
    "gray",
    "violet",
    "cyan",
    "magenta",
    "turquoise",
    "lavender",
    "maroon",
    "navy",
    "olive",
    "teal",
    "gold",
    "silver",
    "beige",
    "coral",
    "mint",
    "peach",
    "other",
  ].map((color) => t(`product.${color}`));

  const sizes = [
    { id: 1, name: "XS", fname: t("product.xs") },
    { id: 2, name: "S", fname: t("product.s") },
    { id: 3, name: "M", fname: t("product.m") },
    { id: 4, name: "L", fname: t("product.l") },
    { id: 5, name: "XL", fname: t("product.xl") },
    { id: 6, name: "XXL", fname: t("product.xxl") },
  ];

  // Filter List Component
  const FilterList = () => (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          sx={{ fontWeight: "bold", fontSize: "1.2em" }}
          component="div"
          id="nested-list-subheader"
        >
          {t("product.filter")}
        </ListSubheader>
      }
    >
      {/* Filter by Color */}
      <ListItemButton onClick={() => handleClick("color")}>
        <ListItemText primary={t("product.color")} />
        {openList["color"] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openList["color"]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box sx={{ pl: 4 }}>
            <FormGroup>
              {colors.map((color, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      value={color}
                      checked={filters.color.includes(color)}
                      onChange={(e) => handleFilterChange(e, "color")}
                    />
                  }
                  label={color}
                />
              ))}
            </FormGroup>
          </Box>
        </List>
      </Collapse>

      {/* Filter by Size */}
      <ListItemButton onClick={() => handleClick("size")}>
        <ListItemText primary={t("product.size")} />
        {openList["size"] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openList["size"]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box sx={{ pl: 4 }}>
            <FormGroup>
              {sizes.map((size) => (
                <FormControlLabel
                  key={size.id}
                  control={
                    <Checkbox
                      value={size.name}
                      checked={filters.size.includes(size.name)}
                      onChange={(e) => handleFilterChange(e, "size")}
                    />
                  }
                  label={`${size.fname}`}
                />
              ))}
            </FormGroup>
          </Box>
        </List>
      </Collapse>

      {/* Filter by Category */}
      <ListItemButton onClick={() => handleClick("category")}>
        <ListItemText primary={t("product.category")} />
        {openList["category"] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openList["category"]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box sx={{ pl: 4 }}>
            <FormGroup>
              {isLoadingCategories ? (
                <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : isErrorCategories ? (
                <Typography color="error">
                  Failed to load categories.
                </Typography>
              ) : (
                categoriesData.map((category) => (
                  <FormControlLabel
                    key={category.id}
                    control={
                      <Checkbox
                        value={category.id} // Numeric value
                        checked={filters.category.includes(category.id)}
                        onChange={(e) => handleFilterChange(e, "category")}
                      />
                    }
                    label={category.name}
                  />
                ))
              )}
            </FormGroup>
          </Box>
        </List>
      </Collapse>
    </List>
  );

  return (
    <Grid
      container
      maxWidth="xl"
      justifyContent="center"
      color="text.secondary"
      padding="0em 1em"
      mb={7}
    >
      {/* Tabs Section */}
      <Grid container justifyContent="center" mt={1} mb={1}>
        {isLoadingParentCategories ? (
          <CircularProgress />
        ) : isErrorParentCategories ? (
          <Typography color="error">
            Failed to load parent categories.
          </Typography>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Parent Categories Tabs"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {/* "All" Tab */}
            <Tab key="all" label={t("product.all")} />
            {/* Parent Categories Tabs */}
            {parentCategories.map((item) => (
              <Tab key={item.id} label={item.name} />
            ))}
          </Tabs>
        )}
      </Grid>

      {/* Main Content */}
      <Grid
        container
        justifyContent="center"
        sx={{ width: { xs: "100%", sm: "90%", md: "100%", lg: "90%" } }}
        mt={1}
      >
        {/* Seller Profile */}
        {userInfo && (
          <Grid container>
            <SellerProfile userInfo={userInfo} />
          </Grid>
        )}

        {/* Filter List for Desktop */}
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          mb={2}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <FilterList />
        </Grid>

        {/* Drawer for Mobile Filters */}
        <Grid
          item
          xs={12}
          md={2}
          sx={{ display: { xs: "block", md: "none" } }}
          textAlign="left"
        >
          <IconButton
            edge="end"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <FilterList />
          </Drawer>
        </Grid>

        {/* Products List */}
        <Grid item xs={12} sm={12} md={10} sx={{ mt: 2 }}>
          <Suspense fallback={<CardsSkeleton />}>
            {isError ? (
              <ErrorPage />
            ) : (
              <InfiniteScroll
                dataLength={allProducts.length}
                next={() => setPage((prevPage) => prevPage + 1)}
                hasMore={hasMore}
                loader={
                  <Grid container justifyContent="center" padding="10px">
                    <CircularProgress />
                  </Grid>
                }
                endMessage={
                  noDataMessage && (
                    <Typography mt={2} align="center">
                      {noDataMessage}
                    </Typography>
                  )
                }
              >
                {isLoading && allProducts.length === 0 ? (
                  <CardsSkeleton />
                ) : allProducts.length === 0 ? (
                  <NoData message={noDataMessage} />
                ) : (
                  <Cards products={allProducts} />
                )}
              </InfiniteScroll>
            )}
          </Suspense>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Products;
