// RelatedProducts.jsx
import React, { useEffect, useState, useContext } from "react";
import Slider from "react-slick";
import {
  Box,
  Button,
  Paper,
  Typography,
  IconButton,
  Skeleton,
} from "@mui/material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Context from "../../../../context/Context";
import useFetchObjects from "../../../../api/useFetchObjects";

// Custom Next Arrow Component with improved design
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        right: 15,
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 2,
        backgroundColor: "#fff",
        border: "2px solid #FFC500",
        boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
        "&:hover": {
          backgroundColor: "#fff",
          borderColor: "#FFA000",
          boxShadow: "0px 6px 12px rgba(0,0,0,0.3)",
        },
      }}
    >
      <ArrowForwardIosIcon sx={{ color: "#FFC500", fontSize: "18px" }} />
    </IconButton>
  );
};

// Custom Previous Arrow Component with improved design
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        left: 15,
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 2,
        backgroundColor: "#fff",
        border: "2px solid #FFC500",
        boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
        "&:hover": {
          backgroundColor: "#fff",
          borderColor: "#FFA000",
          boxShadow: "0px 6px 12px rgba(0,0,0,0.3)",
        },
      }}
    >
      <ArrowBackIosIcon sx={{ color: "#FFC500", fontSize: "18px" }} />
    </IconButton>
  );
};

// Slider settings for react-slick with custom arrows
const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1500,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const RelatedProducts = ({ productId }) => {
  // State to keep track of image indexes for each product
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const direction = localStorage.getItem("direction");

  // For context actions (cart, wishlist)
  const {
    addToCart,
    cartItems,
    wishlistItems,
    addToWishlist,
    removeWishlistItem,
    removeCartItem,
  } = useContext(Context);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: relatedProducts,
    isLoading,
    isError,
  } = useFetchObjects(["related-products"], `products/${productId}/related/`);

  const handleToggleCart = (event, product) => {
    event.stopPropagation();
    if (isInCart(product.id)) {
      removeCartItem(product.id);
    } else {
      addToCart(product);
    }
  };

  const isInCart = (productId) => {
    return cartItems.some((item) => item.id === productId);
  };

  const handleToggleWishList = (event, product) => {
    event.stopPropagation();
    if (isInWishlist(product.id)) {
      removeWishlistItem(product.id);
    } else {
      addToWishlist(product);
    }
  };

  const isInWishlist = (productId) => {
    return wishlistItems.some((item) => item.id === productId);
  };

  const calculateDiscountPrice = (price, discount) => {
    return discount ? (price - (price * discount) / 100).toFixed(2) : price;
  };

  const handleNextImage = (productId, images) => {
    if (images && images.length > 0) {
      setCurrentImageIndex((prevState) => ({
        ...prevState,
        [productId]: (prevState[productId] || 0) + 1,
      }));
    }
  };

  const handlePrevImage = (productId, images) => {
    if (images && images.length > 0) {
      setCurrentImageIndex((prevState) => ({
        ...prevState,
        [productId]: (prevState[productId] || 0) - 1,
      }));
    }
  };

  const getImageIndex = (productId, images) => {
    if (!images || images.length === 0) return 0;
    const index = currentImageIndex[productId] || 0;
    return index >= images.length ? 0 : index < 0 ? images.length - 1 : index;
  };

  // Improved skeleton loading UI using MUI Skeleton components
  if (isLoading) {
    // Render a slider with skeleton cards similar to product cards
    const skeletonCards = [1, 2, 3, 4]; // Number of skeleton slides can be adjusted
    return (
      <Box sx={{ width: "100%", p: 2 }}>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "25px", md: "35px" },
            fontWeight: "bold",
            margin: "20px 5px",
          }}
        >
          {t("manage_product.relatedProducts", "Related Products")}
        </Typography>
        <Slider {...sliderSettings}>
          {skeletonCards.map((skeleton) => (
            <Box key={skeleton} sx={{ px: 1, direction: direction }}>
              <Paper
                sx={{
                  border: "1px solid lightgray",
                  borderRadius: "8px",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  maxHeight: "400px",
                  p: 1,
                }}
              >
                {/* Skeleton for Image */}
                <Skeleton variant="rectangular" height={200} />
                <Box p={1}>
                  {/* Skeleton for Product Title */}
                  <Skeleton variant="text" width="80%" height={30} />
                  {/* Skeleton for Price */}
                  <Skeleton
                    variant="text"
                    width="40%"
                    height={25}
                    sx={{ mt: 1 }}
                  />
                  {/* Skeleton for Button */}
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={40}
                    sx={{ mt: 2 }}
                  />
                </Box>
              </Paper>
            </Box>
          ))}
        </Slider>
      </Box>
    );
  }

  if (!relatedProducts || relatedProducts.length === 0) {
    return (
      <Box sx={{ textAlign: "center", p: 4 }}>
        <Typography variant="h6">
          {t("manage_product.noRelatedProduct", "No related products found")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", p: 2, position: "relative" }}>
      <Typography
        sx={{
          fontSize: { xs: "20px", sm: "25px", md: "35px" },
          fontWeight: "bold",
          margin: "20px 5px",
        }}
      >
        {t("manage_product.relatedProducts", "Related Products")}
      </Typography>

      <Slider {...sliderSettings}>
        {relatedProducts.map((product) => {
          const images = product.images || [];
          const currentImgIndex = getImageIndex(product.id, images);
          const currentImage =
            images?.length > 0
              ? images[currentImgIndex]?.image
              : "default-image-url.jpg";

          // Calculate any discounted price if needed
          const discountedPrice = calculateDiscountPrice(
            product.price,
            product.discount
          );

          return (
            <Box key={product.id} sx={{ px: 1, direction: direction }}>
              <Paper
                sx={{
                  cursor: "pointer",
                  border: "1px solid lightgray",
                  borderRadius: "8px",
                  overflow: "hidden",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  maxHeight: "400px",
                  transition: "box-shadow 0.2s ease-in-out",
                  ":hover": {
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                {/* Product Image Container */}
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "50vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <Box
                    component="img"
                    src={currentImage}
                    alt={product.name}
                    sx={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                  {images?.length > 1 && (
                    <>
                      <IconButton
                        sx={{
                          position: "absolute",
                          left: 10,
                          top: "50%",
                          transform: "translateY(-50%)",
                          backgroundColor: "rgba(0,0,0,0.3)",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "rgba(0,0,0,0.5)",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePrevImage(product.id, images);
                        }}
                      >
                        <ArrowBackIosIcon sx={{ fontSize: "16px" }} />
                      </IconButton>
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: 10,
                          top: "50%",
                          transform: "translateY(-50%)",
                          backgroundColor: "rgba(0,0,0,0.3)",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "rgba(0,0,0,0.5)",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNextImage(product.id, images);
                        }}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
                      </IconButton>
                    </>
                  )}
                </Box>

                {/* Product Information */}
                <Box p={1} flexGrow={1}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      mb: 1,
                    }}
                  >
                    {product.name || t("product.unknown")}
                  </Typography>

                  <Box>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6" fontWeight="bold" color="green">
                        {product.price} {product.currency === 1 ? "؋" : "$"}
                      </Typography>
                      {product.whole_price > 0 && (
                        <>
                          <Box
                            component="span"
                            sx={{
                              height: "3px",
                              borderRadius: "10px",
                              width: "20px",
                              mx: 1,
                              backgroundColor: "green",
                            }}
                          ></Box>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            color="green"
                          >
                            {product.whole_price}{" "}
                            {product.currency === 1 ? "؋" : "$"}
                          </Typography>
                        </>
                      )}
                    </Box>
                    {product.whole_price > 0 && (
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "14px", color: "green", mt: 0.5 }}
                      >
                        {t("home.moreThan")} {product.whole_price_count}{" "}
                        {t("home.wholePrice")}
                      </Typography>
                    )}
                  </Box>

                  {/* Action Buttons */}
                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Button
                      size="small"
                      sx={{
                        textTransform: "capitalize",
                        mr: 1,
                        px: 2,
                        backgroundColor: "#FFB100",
                        color: "#000000",
                        ":hover": { backgroundColor: "#FFC500" },
                      }}
                      onClick={(e) => handleToggleCart(e, product)}
                      disabled={product.quantity === 0}
                    >
                      <ShoppingCartOutlinedIcon
                        fontSize="small"
                        sx={{ mr: 0.5 }}
                      />
                      {isInCart(product.id)
                        ? t("product.removeCard")
                        : t("product.addToCard")}
                    </Button>
                    <IconButton
                      onClick={(e) => handleToggleWishList(e, product)}
                      sx={{
                        color: isInWishlist(product.id) ? "red" : "grey",
                      }}
                    >
                      {isInWishlist(product.id) ? (
                        <Favorite />
                      ) : (
                        <FavoriteBorderOutlined />
                      )}
                    </IconButton>
                  </Box>
                </Box>

                {/* Discount Badge */}
                {product.discount > 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      backgroundColor: "green",
                      color: "white",
                      borderRadius: "5px",
                      px: 1,
                      py: 0.5,
                      fontSize: "0.8rem",
                      zIndex: 2,
                    }}
                  >
                    {product.discount}% {t("product.off")}
                  </Box>
                )}

                {/* Location Footer */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={1}
                  sx={{
                    borderTop: "1px solid #ddd",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <PinDropIcon sx={{ color: "blue", mr: 1 }} />
                  <Typography variant="body2" fontWeight="bold">
                    {product.province_name
                      ? t(
                          `manage_product.${product?.province_name?.toLowerCase()}`
                        )
                      : product.location === 1
                      ? t("product.asanBawar")
                      : t("product.sellerShop")}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default RelatedProducts;
