import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import useDelete from "../../../api/useDelete";
import useUpdate from "../../../api/useUpdate";
import { useAuth } from "../../../context/AuthContext";
import useAdd from "../../../api/useAdd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ParentCategory = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [imagePreview, setImagePreview] = useState([]);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({ name: "", description: "" });
  const [filter, setFilter] = useState("");

  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["CategoriesList"],
    `parent-category/?filter=${filter}`,
    token
  );

  const { handleAdd, resetForm, loading } = useAdd("parent-category", token);
  const { handleUpdate } = useUpdate(
    "parent-category",
    token,
    "/dashboard/parent-category"
  );
  const { handleDelete, ConfirmDialog } = useDelete("parent-category", token);

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  useEffect(() => {
    if (resetForm) {
      setFormData({ name: "", description: "", image: "" });
      setFormErrors({});
      // Optionally refetch to refresh the grid data after add:
      refetch();
      navigate("/dashboard/categories");
    }
  }, [resetForm, navigate, refetch]);

  const handleEdit = (row) => {
    setOpen(true);
    setImagePreview(row?.image);
    setCurrentCategory(row);
    setFormData({
      name: row?.name || "",
      description: row?.description || "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("categories.fieldRequired");
    });
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      const formObj = new FormData();
      Object.keys(formData).forEach((key) =>
        formObj.append(key, formData[key])
      );
      handleUpdate(currentCategory?.id, formObj);
      setOpen(false);
    }
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("categories.fieldRequired");
    });
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      const formObj = new FormData();
      Object.keys(formData).forEach((key) =>
        formObj.append(key, formData[key])
      );
      handleAdd(formObj);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const columns = [
    {
      field: "id",
      headerName: t("categories.id"),
      flex: 0.5,
      // Safely check for row?.id
      valueGetter: (params) => params?.row?.id || "",
    },
    {
      field: "name",
      headerName: t("categories.categoryName"),
      flex: 2,
      cellClassName: "name-column--cell",
      // Safely check for row?.name
      valueGetter: (params) => params?.row?.name || "",
    },
    {
      field: "description",
      headerName: t("categories.description", "Description"),
      flex: 2,
      cellClassName: "name-column--cell",
      // Safely check for row?.description
      valueGetter: (params) => params?.row?.description || "",
    },
    {
      field: "actions",
      headerName: t("categories.action"),
      flex: 1,
      renderCell: (params) => {
        const row = params?.row || {};
        return (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleEdit(row)}
              style={{ margin: "0em 1em" }}
            >
              {t("categories.edit")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDelete(row?.id)}
            >
              {t("categories.delete")}
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header
        title={t("categories.parentCategory", "Parent Category")}
        subtitle={t("categories.parentList", "Parent Categories List")}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setAddOpen(true)}
      >
        {t("categories.addCategory")}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate("/dashboard/categories")}
        sx={{ margin: "0px 10px" }}
      >
        {t("categories.subCategory", "Sub Category")}
      </Button>

      <Box
        m="20px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          t("categories.loading")
        ) : isError ? (
          t("categories.fieldLoading")
        ) : (
          <DataGrid
            rows={Array.isArray(data) ? data : []}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            rowHeight={80}
          />
        )}
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            style={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            <Typography variant="h5">{t("categories.editCategory")}</Typography>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <br /> <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  size="small"
                  label={t("categories.categoryName")}
                  name="name"
                  value={formData.name}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  size="small"
                  label={t("categories.description", "Description")}
                  name="description"
                  value={formData.description}
                  error={!!formErrors.description}
                  helperText={formErrors.description}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="contained"
              style={{ margin: "0em 1em" }}
            >
              {t("categories.cancel")}
            </Button>
            <Button
              type="submit"
              disabled={loading}
              color="secondary"
              variant="contained"
            >
              {loading ? <CircularProgress /> : t("categories.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={addOpen}
        onClose={() => setAddOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleAddCategory}>
          <DialogTitle
            style={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            <Typography variant="h5">{t("categories.addCategory")}</Typography>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <br /> <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  size="small"
                  label={t("categories.categoryName")}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  size="small"
                  label={t("categories.description", "Description")}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  error={!!formErrors.description}
                  helperText={formErrors.description}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setAddOpen(false)}
              color="primary"
              variant="contained"
              style={{ margin: "0em 1em" }}
            >
              {t("categories.cancel")}
            </Button>
            <Button
              disabled={loading}
              type="submit"
              color="secondary"
              variant="contained"
            >
              {loading ? <CircularProgress /> : t("categories.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmDialog />
    </Box>
  );
};

export default ParentCategory;
