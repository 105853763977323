import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  Autocomplete,
  CircularProgress,
  useTheme,
} from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";

const PaymentDialog = ({
  open,
  onClose,
  onSubmit,
  initialData,
  loading,
  editLoading,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [formData, setFormData] = useState({
    id: initialData?.id || "",
    order: initialData?.order || "",
    date: initialData?.date || "",
    payment_method: initialData?.payment_method || 1,
    total_amount_afg: initialData?.total_amount_afg || "",
    total_amount_usd: initialData?.total_amount_usd || "",
    selectedOrder: initialData?.selectedOrder || null,
  });

  useEffect(() => {
    setFormData({
      id: initialData?.id,
      order: initialData?.order,
      date: initialData?.date,
      payment_method: 1,
      total_amount_afg: initialData?.total_amount_afg,
      total_amount_usd: initialData?.total_amount_usd,
      selectedOrder: initialData?.selectedOrder,
    });
  }, [initialData]);

  const { data: orders } = useFetchObjects(["OrdersList"], `orders/`, token);

  // Ensure `orders` is an array before passing it to Autocomplete
  const orderOptions = Array.isArray(orders) ? orders : [];

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleOrderChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      order: newValue.id,
      id: newValue?.id || "",
      date: newValue?.order_date || "",
      total_amount_afg: newValue?.total_amount_afg || "",
      total_amount_usd: newValue?.total_amount_usd || "",
    }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h4" fontWeight={"bold"}>
          {initialData ? t("payment.editOrder") : t("payment.addOrder")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ padding: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={orderOptions}
                getOptionLabel={(option) =>
                  `${t("payment.orderId")}: ${option.id} - ${
                    option.display_user.firstname
                  } ${option.display_user.lastname}`
                } // Show Order ID, First Name and Last Name
                value={
                  (orderOptions &&
                    orderOptions?.find(
                      (order) => order.id === formData?.order || null
                    )) ||
                  null
                }
                onChange={handleOrderChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("payment.selectOrderId")}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t("payment.date")}
                type="datetime-local"
                fullWidth
                name="date"
                value={formData?.date}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} display={"none"}>
              <TextField
                label={t("payment.paymentMethod")}
                fullWidth
                name="payment_method"
                value={4}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("payment.totalAmount1")}
                fullWidth
                name="total_amount_afg"
                value={formData.total_amount_afg}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("payment.totalAmount2")}
                fullWidth
                name="total_amount_usd"
                value={formData.total_amount_usd}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          style={{ margin: "0em 1em" }}
        >
          {t("payment.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {loading || editLoading ? (
            <CircularProgress size={"small"} />
          ) : (
            <>{initialData ? t("payment.update") : t("payment.add")}</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
