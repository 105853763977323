// src/pages/dashboard/categories/Add.jsx
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useTranslation } from "react-i18next";
import useAdd from "../../../../api/useAdd";
import useFetchObjects from "../../../../api/useFetchObjects";
import { Autocomplete } from "@mui/material";

const Add = ({ open, onClose, token, onSuccess }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { handleAdd, loading, resetForm } = useAdd("sub-category", token);

  const [formData, setFormData] = useState({
    main_category: null,
    title: "",
    description: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const {
    data: categories,
    isLoading,
    isError,
  } = useFetchObjects(["categories"], "categories/", token);

  useEffect(() => {
    if (resetForm) {
      setFormData({
        main_category: null,
        title: "",
        description: "",
      });
      setFormErrors({});
      onSuccess();
    }
  }, [resetForm, onSuccess]);

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setFormErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    if (!formData.main_category)
      errors.main_category = t("categories.fieldRequired");
    if (!formData.title) errors.title = t("categories.fieldRequired");

    if (Object.keys(errors).length === 0) {
      const payload = {
        main_category: formData.main_category,
        title: formData.title,
        description: formData.description,
      };
      handleAdd(payload);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle
          style={{
            textAlign: "center",
            backgroundColor: "#3f51b5",
            color: "#fff",
          }}
        >
          <Typography variant="h5">{t("categories.addSubCategory")}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
        >
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              {isLoading ? (
                <CircularProgress />
              ) : isError ? (
                <Typography color="error">
                  {t("categories.failedToLoadCategories")}
                </Typography>
              ) : (
                <Autocomplete
                  options={categories || []}
                  getOptionLabel={(option) => option.name}
                  value={
                    categories.find(
                      (cat) => cat.id === formData.main_category
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleChange("main_category", newValue?.id || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("categories.mainCategory")}
                      variant="outlined"
                      error={!!formErrors.main_category}
                      helperText={formErrors.main_category}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                size="small"
                label={t("categories.title")}
                name="title"
                value={formData.title}
                onChange={(e) => handleChange("title", e.target.value)}
                error={!!formErrors.title}
                helperText={formErrors.title}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label={t("categories.description")}
                name="description"
                value={formData.description}
                onChange={(e) => handleChange("description", e.target.value)}
                error={!!formErrors.description}
                helperText={formErrors.description}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: colors.primary[400],
            justifyContent: "center",
          }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ m: 1 }}
          >
            {t("categories.cancel")}
          </Button>
          <Button
            type="submit"
            disabled={loading}
            color="secondary"
            variant="contained"
          >
            {loading ? <CircularProgress size={24} /> : t("categories.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Add;
