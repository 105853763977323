import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAdd from "../../../api/useAdd";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";

const Add = ({ open, onClose, token }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { handleAdd, loading, resetForm } = useAdd(
    "delivery-costs",
    token,
    t("deliveryCost.successMessage"),
    t("deliveryCost.fieldMessage")
  );

  const [formData, setFormData] = useState({
    origin: "",
    destination: "",
    from_seller_price: "",
    from_asanbawar_price: "",
    origin_price: "",
    argent_price: "",
  });

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (resetForm) {
      setFormData({
        origin: "",
        destination: "",
        from_seller_price: "",
        from_asanbawar_price: "",
        origin_price: "",
        argent_price: "",
      });
      onClose();
    }
  }, [resetForm, onClose]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddDeliveryCost = (event) => {
    event.preventDefault();

    // Basic validation
    const errors = {};
    if (!formData.origin) errors.origin = t("deliveryCost.errors.required");
    if (!formData.destination)
      errors.destination = t("deliveryCost.errors.required");

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    handleAdd(formData);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleAddDeliveryCost}>
        <DialogTitle
          style={{
            textAlign: "center",
            backgroundColor: "#3f51b5",
            color: "#fff",
          }}
        >
          <Typography variant="h5">{t("deliveryCost.add")}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
        >
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                size="small"
                label={t("deliveryCost.origin")}
                name="origin"
                value={formData.origin}
                onChange={handleChange}
                error={!!formErrors.origin}
                helperText={formErrors.origin}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label={t("deliveryCost.destination")}
                name="destination"
                value={formData.destination}
                onChange={handleChange}
                error={!!formErrors.destination}
                helperText={formErrors.destination}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label={t("deliveryCost.fromSellerPrice")}
                name="from_seller_price"
                value={formData.from_seller_price}
                onChange={handleChange}
                error={!!formErrors.from_seller_price}
                helperText={formErrors.from_seller_price}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label={t("deliveryCost.fromAsanbawarPrice")}
                name="from_asanbawar_price"
                value={formData.from_asanbawar_price}
                onChange={handleChange}
                error={!!formErrors.from_asanbawar_price}
                helperText={formErrors.from_asanbawar_price}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label={t("deliveryCost.originPrice")}
                name="origin_price"
                value={formData.origin_price}
                onChange={handleChange}
                error={!!formErrors.origin_price}
                helperText={formErrors.origin_price}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label={t("deliveryCost.argentPrice")}
                name="argent_price"
                value={formData.argent_price}
                onChange={handleChange}
                error={!!formErrors.argent_price}
                helperText={formErrors.argent_price}
                fullWidth
                type="number"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: colors.primary[400],
            justifyContent: "center",
          }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ m: 1 }}
          >
            {t("deliveryCost.cancel")}
          </Button>
          <Button
            disabled={loading}
            type="submit"
            color="secondary"
            variant="contained"
          >
            {loading ? <CircularProgress size={24} /> : t("deliveryCost.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Add;
