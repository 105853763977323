import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  Paper,
  Stack,
  FormLabel,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import { DeleteOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Context from "../../../../context/Context";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";
import { useTranslation } from "react-i18next";

const ShoppingCart = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { updateCartItemQuantity, removeCartItem } = useContext(Context);

  // Cart Items and selection state
  const [cartItems, setCartItems] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const afghanPhoneRegex = /^(\+93|0)?(7[0-9]{8})$/;

  const [validationMessages, setValidationMessages] = useState({
    phone: "",
    address: "",
    quantity: {},
  });
  const [quantityErrorMessage, setQuantityErrorMessage] = useState("");
  const [totalDollarsAmount, setTotalDollarsAmount] = useState(0);
  const [totalAfghanisAmount, setTotalAfghanisAmount] = useState(0);

  // Updated formData; order items are stored under "order_items"
  const [formData, setFormData] = useState({
    user: user?.user.id,
    address: "",
    contact: "",
    description: "",
    order_items: [],
    payment_to: 1, // not used further in this version
    payment_type: null, // will store 1 for Cash, 2 for Online
  });

  const { handleAdd, resetForm } = useAdd(
    "orders",
    token,
    t("product.orderCreatedSuccess"),
    t("product.orderCreateError")
  );

  // --- Single Payment Dialog State ---
  // User must select both Payment Destination and Payment Type.
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  // Payment Destination (display only – if needed)
  const [selectedDestination, setSelectedDestination] = useState(""); // "1" for Asanbawar, "2" for Seller
  // Payment Type: "1" for Cash, "2" for Online
  const [selectedPaymentType, setSelectedPaymentType] = useState("");

  // ========= Helper Functions =========
  const handleRemoveCartItem = (itemId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    removeCartItem(itemId);
  };

  const handleUpdateCartItemQuantity = (itemId, newQuantity) => {
    const item = cartItems.find((i) => i.id === itemId);
    if (newQuantity >= item.whole_price_count) {
      setQuantityErrorMessage(
        `${t("product.greatNews")} ${item?.whole_price_count} ${t(
          "product.unitOf"
        )} ${item?.name}${t("product.qualify")}`
      );
    } else {
      setQuantityErrorMessage("");
    }
    if (newQuantity > item.quantity) {
      setValidationMessages((prev) => ({
        ...prev,
        quantity: { [itemId]: `${item.name} ${t("product.notEnough")}` },
      }));
    } else {
      setValidationMessages((prev) => ({
        ...prev,
        quantity: { ...prev.quantity, [itemId]: "" },
      }));
    }
    const updatedCartItems = cartItems.map((i) =>
      i.id === itemId ? { ...i, userQuantity: newQuantity } : i
    );
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    updateCartItemQuantity(itemId, newQuantity);
  };

  const handleSelectProduct = (productId, checked) => {
    if (checked) {
      setSelectedProductIds((prev) => [...prev, productId]);
    } else {
      setSelectedProductIds((prev) => prev.filter((id) => id !== productId));
    }
  };

  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      const allIds = cartItems.map((item) => item.id);
      setSelectedProductIds(allIds);
    } else {
      setSelectedProductIds([]);
    }
  };

  // ========= useEffect Hooks =========
  useEffect(() => {
    if (resetForm) {
      // Remove only the ordered products from the cart.
      const remainingItems = cartItems.filter(
        (item) => !selectedProductIds.includes(item.id)
      );
      setCartItems(remainingItems);
      localStorage.setItem("cartItems", JSON.stringify(remainingItems));
      setSelectedProductIds([]);
      setSelectAll(false);
      setFormData({
        user: user?.user.id,
        address: "",
        contact: "",
        description: "",
        order_items: [],
        payment_to: 1,
        payment_type: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(storedCartItems);
  }, []);

  useEffect(() => {
    let dollarTotal = 0;
    let afghaniTotal = 0;
    cartItems.forEach((item) => {
      const price =
        item.userQuantity < item.whole_price_count
          ? item.price
          : item.whole_price;
      if (item.currency === 1) {
        afghaniTotal += item.userQuantity * price;
      } else {
        dollarTotal += item.userQuantity * price;
      }
    });
    setTotalDollarsAmount(dollarTotal);
    setTotalAfghanisAmount(afghaniTotal);
  }, [cartItems]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationMessages((prev) => ({ ...prev, [name]: "" }));
    if (name === "address" || name === "contact") {
      setQuantityErrorMessage("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!afghanPhoneRegex.test(formData.contact)) {
      setValidationMessages((prev) => ({
        ...prev,
        phone: t("product.phoneNum"),
      }));
      return;
    }
    if (!formData.address) {
      setValidationMessages((prev) => ({
        ...prev,
        address: t("product.enterAddress"),
      }));
      return;
    }
    for (const item of cartItems) {
      if (validationMessages.quantity[item.id]) {
        setQuantityErrorMessage(validationMessages.quantity[item.id]);
        return;
      }
    }
    if (selectedProductIds.length === 0) {
      toast.error(t("product.selectAtLeastOne"));
      return;
    }
    // Filter order items: include only selected products.
    const orderItems = cartItems
      .filter((item) => selectedProductIds.includes(item.id))
      .map((item) => {
        const price =
          item.userQuantity < item.whole_price_count
            ? item.price
            : item.whole_price;
        return {
          product: item.id,
          quantity: item.userQuantity,
          price,
          user: item.user,
        };
      });
    setFormData((prevData) => ({
      ...prevData,
      order_items: orderItems,
    }));
    // Open the payment dialog.
    setOpenPaymentDialog(true);
  };

  // ===== Payment Dialog Handlers =====
  const handleDestinationChange = (e) => {
    setSelectedDestination(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSelectedPaymentType(e.target.value);
  };

  const handlePaymentDialogClose = () => {
    setOpenPaymentDialog(false);
    setSelectedDestination("");
    setSelectedPaymentType("");
  };

  const handlePaymentConfirm = () => {
    // Directly create a new object with the parsed payment type.
    const parsedPaymentType = parseInt(selectedPaymentType, 10);
    const newFormData = { ...formData, payment_type: parsedPaymentType };
    setOpenPaymentDialog(false);
    // Call the add handler with the updated order payload.
    if (token) {
      handleAdd(newFormData);
    } else {
      toast.error(t("product.loginToOrder"));
    }
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      padding={isMobile ? "20px" : "0px 20px"}
      mb={4}
      spacing={2}
    >
      <Grid item xs={12} md={11.7} borderBottom="5px solid green" pb={2}>
        <Typography mt={2} variant="h2" textAlign="center" fontWeight="bold">
          {t("product.shoppingCart")}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Typography
          height="4vh"
          textAlign="center"
          padding="0px 10px"
          pt="4"
          color="error.main"
        >
          {quantityErrorMessage}
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <TableContainer component={Paper} sx={{ maxHeight: "69vh" }}>
          <Table>
            <TableHead sx={{ bgcolor: "#dee3e0" }}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    color="primary"
                  />
                </TableCell>
                <TableCell align="center">{t("product.product")}</TableCell>
                <TableCell align="center">{t("product.retailPrice")}</TableCell>
                <TableCell align="center">{t("product.wholePrice")}</TableCell>
                <TableCell align="center">{t("product.quantity")}</TableCell>
                <TableCell align="center">{t("product.inStock")}</TableCell>
                <TableCell align="center">{t("product.total")}</TableCell>
                <TableCell align="center">{t("product.action")}</TableCell>
                <TableCell align="center">{t("product.chat")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map((product) => (
                <TableRow key={product.id} hover>
                  <TableCell align="center">
                    <Checkbox
                      checked={selectedProductIds.includes(product.id)}
                      onChange={(e) =>
                        handleSelectProduct(product.id, e.target.checked)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Box display="flex" alignItems="center">
                      <Box mr={2}>
                        <img
                          src={
                            product.images && product.images.length > 0
                              ? product.images[0].image
                              : "placeholder-image-url.jpg"
                          }
                          alt={product.name}
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Typography variant="body1" fontWeight="bold">
                        {product.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row">
                      <Typography>
                        {product.price?.toFixed(2)}{" "}
                        {product.currency === 1 ? "؋" : "$"}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {product.whole_price?.toFixed(2)}{" "}
                      {product.currency === 1 ? "؋" : "$"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={product.userQuantity}
                      onChange={(e) =>
                        handleUpdateCartItemQuantity(
                          product.id,
                          parseInt(e.target.value, 10)
                        )
                      }
                      sx={{ maxWidth: { sm: "10vw", md: "7vw" } }}
                      error={!!validationMessages.quantity[product.id]}
                      helperText={validationMessages.quantity[product.id]}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    {product.quantity}
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row">
                      <Typography>
                        {product.userQuantity >= product.whole_price_count &&
                        product?.whole_price !== 0
                          ? (
                              product.whole_price * product.userQuantity
                            ).toFixed(2)
                          : (product.price * product.userQuantity).toFixed(
                              2
                            )}{" "}
                        {product.currency === 1 ? "؋" : "$"}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => handleRemoveCartItem(product.id)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <MessageIcon
                      onClick={() => navigate("/chat", { state: { product } })}
                      sx={{ color: "blue", cursor: "pointer" }}
                      titleAccess={t("product.chatWith")}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper elevation={3} sx={{ padding: 2, height: "69vh" }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            gutterBottom
            sx={{ borderBottom: "3px solid green", pb: 1 }}
            textAlign="center"
          >
            {t("product.cartSummary")}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold">
              {t("product.totalUsd")}:
            </Typography>
            <Typography fontWeight="bold">
              {totalDollarsAmount.toFixed(2)}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="body1" fontWeight="bold">
              {t("product.totalAfn")}:
            </Typography>
            <Typography fontWeight="bold">
              {totalAfghanisAmount.toFixed(2)}
            </Typography>
          </Box>
          <Box mb={1}>
            <FormLabel>{t("product.address")}</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder={t("product.writeAddress")}
              name="address"
              value={formData.address}
              onChange={handleChangeInput}
              InputProps={{ inputProps: { min: 0 } }}
              rows={2}
              multiline
              error={!!validationMessages.address}
              helperText={validationMessages.address}
            />
          </Box>
          <Box mb={1}>
            <FormLabel>{t("product.activeNum")}</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="contact"
              value={formData.contact}
              onChange={handleChangeInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+93</InputAdornment>
                ),
              }}
              error={!!validationMessages.phone}
              helperText={validationMessages.phone}
            />
          </Box>
          <Box mb={1}>
            <FormLabel>{t("product.description")}</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder={t("product.writeDescription")}
              name="description"
              value={formData.description}
              onChange={handleChangeInput}
              InputProps={{ inputProps: { min: 0 } }}
              rows={3}
              multiline
            />
          </Box>
          <Box mt={1}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              {t("product.checkout")}
            </Button>
          </Box>
        </Paper>
      </Grid>

      {/* Single Payment Dialog */}
      <Dialog
        open={openPaymentDialog}
        onClose={handlePaymentDialogClose}
        PaperProps={{
          sx: {
            borderRadius: 3,
            padding: 3,
            backgroundColor: "#f7f7f7",
            boxShadow: 5,
            maxWidth: 500,
            width: "90%",
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}>
          {t("product.selectPaymentMethod")}
        </DialogTitle>
        <DialogContent dividers>
          {/* Payment Destination Section */}
          <Box mb={3}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
              {t("product.destination")}
            </Typography>
            <RadioGroup
              value={selectedDestination}
              onChange={handleDestinationChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label={t("product.toAsanbawar")}
              />
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label={t("product.toSeller")}
              />
            </RadioGroup>
          </Box>
          {/* Payment Type Section */}
          <Box>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
              {t("product.selectPaymentType")}
            </Typography>
            <RadioGroup
              value={selectedPaymentType}
              onChange={handleTypeChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label={t("product.cash")}
              />
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label={t("product.online")}
              />
            </RadioGroup>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
          <Button
            onClick={handlePaymentConfirm}
            variant="contained"
            color="primary"
            disabled={!(selectedDestination && selectedPaymentType)}
            sx={{ minWidth: 120 }}
          >
            {t("product.accept")} (
            {selectedPaymentType === "1"
              ? "Cash"
              : selectedPaymentType === "2"
              ? "Online"
              : "N/A"}
            )
          </Button>
          <Button
            onClick={handlePaymentDialogClose}
            variant="outlined"
            color="secondary"
            sx={{ minWidth: 120 }}
          >
            {t("product.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ShoppingCart;
