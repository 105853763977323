import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  IconButton,
  Stack,
  TextField,
  MenuItem,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import useUpdate from "../../../api/useUpdate";
import useDelete from "../../../api/useDelete";
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context/AuthContext";

const EditProductDialog = ({ open, onClose, product }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  // Update and delete hooks
  const { handleUpdate, loading } = useUpdate(
    "products",
    token,
    "/dashboard/products"
  );

  // Fetch categories if not included within the product prop
  const { data: fetchedCategories } = useFetchObjects(
    ["categories"],
    "categories/",
    token
  );

  const {
    data: productSizes,
    isLoading: productSizesIsLoading,
    isError: productSizesIsError,
    refetch: productSizesRefetch,
  } = useFetchObjects(["sizes"], `product-size`, token);

  // List of provinces (adjust as needed)
  const provinces = [
    { id: 1, label: t("manage_product.badakhshan") },
    { id: 2, label: t("manage_product.badghis") },
    { id: 3, label: t("manage_product.baghlan") },
    { id: 4, label: t("manage_product.balkh") },
    { id: 5, label: t("manage_product.bamyan") },
    { id: 6, label: t("manage_product.daykundi") },
    { id: 7, label: t("manage_product.farah") },
    { id: 8, label: t("manage_product.faryab") },
    { id: 9, label: t("manage_product.ghazni") },
    { id: 10, label: t("manage_product.ghor") },
    { id: 11, label: t("manage_product.helmand") },
    { id: 12, label: t("manage_product.herat") },
    { id: 13, label: t("manage_product.jawzjan") },
    { id: 14, label: t("manage_product.kabul") },
    { id: 15, label: t("manage_product.kandahar") },
    { id: 16, label: t("manage_product.kapisa") },
    { id: 17, label: t("manage_product.khost") },
    { id: 18, label: t("manage_product.kunar") },
    { id: 19, label: t("manage_product.kunduz") },
    { id: 20, label: t("manage_product.laghman") },
    { id: 21, label: t("manage_product.logar") },
    { id: 22, label: t("manage_product.nangarhar") },
    { id: 23, label: t("manage_product.nimroz") },
    { id: 24, label: t("manage_product.nuristan") },
    { id: 25, label: t("manage_product.paktika") },
    { id: 26, label: t("manage_product.paktia") },
    { id: 27, label: t("manage_product.panjshir") },
    { id: 28, label: t("manage_product.parwan") },
    { id: 29, label: t("manage_product.samangan") },
    { id: 30, label: t("manage_product.Sar-e Pol") },
    { id: 31, label: t("manage_product.takhar") },
    { id: 32, label: t("manage_product.uruzgan") },
    { id: 33, label: t("manage_product.wardak") },
    { id: 34, label: t("manage_product.zabul") },
  ];

  // Internal state (no validations)
  const [formData, setFormData] = useState({
    user: "",
    name: "",
    category: "",
    price: "",
    whole_price: "",
    whole_price_count: "",
    currency: "",
    quantity: "",
    description: "",
    discount: "",
    color: [],
    size: [],
    location: "",
    province: "",
    is_visible_seller_info: "",
    uploaded_images: [],
  });

  const [formErrors, setFormErrors] = useState({
    user: "",
    name: "",
    category: "",
    price: "",
    currency: "",
    quantity: "",
    description: "",
    discount: "",
    color: [],
    size: "",
    uploaded_images: [],

    location: "",
    is_visible_seller_info: "",
  });

  const [imagePreview, setImagePreview] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);

  // Prepopulate form fields when product prop changes.
  useEffect(() => {
    if (product) {
      setFormData({
        user: product?.user || "",
        name: product?.name || "", // Ensure product name is set
        category: product?.category?.id || product?.category || "",
        price: product?.price || "",
        whole_price: product?.whole_price || "",
        whole_price_count: product?.whole_price_count || "",
        currency: product?.currency || "",
        quantity: product?.quantity || "",
        description: product?.description || "",
        discount: product?.discount || "",
        color: product?.colors || [],
        size: product?.sizes || [],
        location: product?.location || "",
        province: product?.province || "",
        is_visible_seller_info: product?.is_visible_seller_info
          ? "true"
          : "false",
        uploaded_images: [],
      });
      if (product.images) {
        setImagePreview(
          product.images.map((img) => ({ url: img.image, id: img.id }))
        );
      } else {
        setImagePreview([]);
      }
    }
  }, [product]);

  // Reset slider index when image preview changes.
  useEffect(() => {
    setCurrentSlide(0);
  }, [imagePreview]);

  // Update form fields when inputs change.
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { handleDelete: deleteImage, ConfirmDialog: ConfirmDialogImage } =
    useDelete(`images`, token);

  const handleImageChange = (event) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const newImageUrls = files.map((file) => ({
        url: URL.createObjectURL(file),
        id: null, // Newly uploaded images won't have an ID yet.
      }));

      // Combine the new images with the existing image previews
      setImagePreview((prevPreview) => [
        ...(prevPreview || []),
        ...newImageUrls,
      ]);

      // Update form data with the actual files
      setFormData((prevData) => ({
        ...prevData,
        uploaded_images: [...(prevData.uploaded_images || []), ...files],
      }));

      // Clear any error related to image uploads
      setFormErrors((prevErrors) => ({ ...prevErrors, uploaded_images: "" }));
    }
  };

  const handleDeleteImage = (index, imageId) => {
    if (imageId) {
      // Call the API or use the `deleteImage` function to remove the image from the database.
      deleteImage(imageId);
    }

    // Remove the image from the local preview.
    setImagePreview((prevPreview) =>
      (prevPreview || []).filter((_, i) => i !== index)
    );

    // Update the form data to remove the corresponding file from the uploaded images.
    setFormData((prevData) => {
      const updatedUploadedImages = (prevData.uploaded_images || []).filter(
        (_, i) => i !== index
      );
      return { ...prevData, uploaded_images: updatedUploadedImages };
    });
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < imagePreview.length - 3 ? prevIndex + 1 : 0
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : imagePreview.length - 3
    );
  };


  // Submission handler: build a FormData payload and update the product.
  const handleSubmit = (e) => {
    e.preventDefault();
    const productData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "uploaded_images" && formData[key].length > 0) {
        formData[key].forEach((file) =>
          productData.append("uploaded_images", file)
        );
      } else if (key === "size" && formData[key].length > 0) {
        formData[key].forEach((size) => productData.append("size", size.id));
      } else if (key === "color" && formData[key].length > 0) {
        formData[key].forEach((color) =>
          productData.append("color", color.name || color)
        );
      } else {
        productData.append(key, formData[key]);
      }
    });
    console.log("Submitting edit for product id:", formData);
    handleUpdate(product?.id, productData);
    onClose();
  };

  // Dummy color options.
  const colorOptions = [
    t("product.red"),
    t("product.blue"),
    t("product.green"),
    t("product.yellow"),
    t("product.black"),
    t("product.white"),
    t("product.orange"),
    t("product.purple"),
    t("product.pink"),
    t("product.brown"),
    t("product.gray"),
    t("product.violet"),
    t("product.cyan"),
    t("product.magenta"),
    t("product.turquoise"),
    t("product.lavender"),
    t("product.maroon"),
    t("product.navy"),
    t("product.olive"),
    t("product.teal"),
    t("product.gold"),
    t("product.silver"),
    t("product.beige"),
    t("product.coral"),
    t("product.mint"),
    t("product.peach"),
    t("product.other"),
  ];
  console.log(formData);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DialogTitle
          sx={{
            textAlign: "center",
            backgroundColor: "#3f51b5",
            color: "#fff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">
            {t("manage_product.editProduct")}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{ padding: 2, maxHeight: "65vh", overflowY: "auto" }}
        >
          <Grid container spacing={3}>
            {/* Product Name */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={t("manage_product.productName")}
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={t("manage_product.description")}
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
            {/* Category */}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label={t("manage_product.category")}
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                {(
                  (product && product.categoryOptions) ||
                  fetchedCategories ||
                  []
                ).map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* Province Autocomplete */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                size="small"
                options={provinces}
                getOptionLabel={(option) => option.label}
                value={
                  provinces.find((p) => p.id === formData.province) || null
                }
                onChange={(event, newValue) =>
                  setFormData({
                    ...formData,
                    province: newValue ? newValue.id : "",
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("manage_product.selectProvince")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {/* Seller/User */}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label={t("manage_product.sellerName")}
                name="user"
                value={formData.user}
                onChange={handleChange}
              >
                {product?.userOptions &&
                  product.userOptions.map((u) => (
                    <MenuItem key={u.id} value={u.id}>
                      {u.first_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            {/* Price */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label={t("manage_product.price")}
                name="price"
                type="number"
                value={formData.price}
                onChange={handleChange}
              />
            </Grid>
            {/* Whole Price */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                size="small"
                label={t("manage_product.wholePrice")}
                name="whole_price"
                value={formData.whole_price}
                onChange={handleChange}
              />
            </Grid>
            {/* Whole Price Count */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                size="small"
                label={t("manage_product.wholePriceCount")}
                name="whole_price_count"
                value={formData.whole_price_count}
                onChange={handleChange}
              />
            </Grid>
            {/* Currency */}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label={t("manage_product.currency")}
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              >
                <MenuItem value={1}>{t("manage_product.afg")}</MenuItem>
                <MenuItem value={2}>{t("manage_product.usd")}</MenuItem>
              </TextField>
            </Grid>
            {/* Quantity */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label={t("manage_product.quantity")}
                name="quantity"
                type="number"
                value={formData.quantity}
                onChange={handleChange}
              />
            </Grid>
            {/* Discount */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label={t("manage_product.discount")}
                name="discount"
                value={formData.discount}
                onChange={handleChange}
                InputProps={{
                  min: 0,
                  max: 100,
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Size */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                options={productSizes || []} // List of available size objects
                getOptionLabel={(option) => option.size || ""} // Display the `size` field in the dropdown
                value={formData.size || []} // Show selected sizes as objects
                onChange={(event, newValue) => {
                  // Toggle logic: Check if any size in `newValue` already exists in `formData.size`
                  const toggledSizes = newValue.filter(
                    (size) =>
                      !formData.size.some((existing) => existing.id === size.id)
                  );

                  // Remove sizes from `formData.size` that were deselected
                  const remainingSizes = formData.size.filter((existing) =>
                    newValue.some((size) => size.id === existing.id)
                  );

                  // Combine the remaining sizes and the newly added sizes
                  setFormData({
                    ...formData,
                    size: [...remainingSizes, ...toggledSizes],
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t("manage_product.size")}
                    placeholder={t("manage_product.size")}
                  />
                )}
              />
            </Grid>
            {/* Color Autocomplete */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                size="small"
                multiple
                options={colorOptions}
                value={formData?.color?.map((color) => color.name)}
                onChange={(event, newValue) => {
                  const updatedColors = newValue.map((name) => {
                    const existing = formData.color.find(
                      (c) => c.name === name
                    );
                    return existing || { id: null, name };
                  });
                  setFormData({ ...formData, color: updatedColors });
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("manage_product.selectColor")}
                    placeholder={t("manage_product.chooseColor")}
                  />
                )}
              />
            </Grid>
            {/* Location */}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label={t("manage_product.location")}
                name="location"
                value={formData.location}
                onChange={handleChange}
              >
                <MenuItem value={1}>
                  {t("manage_product.asanbawarStock")}
                </MenuItem>
                <MenuItem value={2}>{t("manage_product.sellerShop")}</MenuItem>
              </TextField>
            </Grid>
            {/* Radio Group for Seller Info Visibility */}
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {t("manage_product.doYouWant")}
                </FormLabel>
                <RadioGroup
                  row
                  name="is_visible_seller_info"
                  value={String(formData.is_visible_seller_info)}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      is_visible_seller_info: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={t("manage_product.yes")}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={t("manage_product.no")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* Image Upload & Preview Slider */}
            <Grid item xs={12}>
              <Box sx={{ width: "90%", margin: "auto", mt: 4 }}>
                <input
                  id="image-input"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />

                {/* Custom button to trigger the input field */}
                <Stack alignItems="center">
                  <label htmlFor="image-input">
                    <Button
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                      component="span"
                      color="secondary"
                    >
                      {t("manage_product.uploadImages")}
                    </Button>
                  </label>
                </Stack>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    onClick={prevSlide}
                    disabled={imagePreview.length <= 3}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                      overflow: "hidden",
                      width: "36rem", // Width to accommodate 3 images
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        transition: "transform 0.5s ease-in-out",
                        transform: `translateX(-${currentIndex * 12}rem)`, // Shift by one image (12rem) at a time
                      }}
                    >
                      {imagePreview.map((image, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "12rem",
                            height: "10rem",
                            marginRight: "0.5rem",
                            position: "relative",
                            border: "1px solid grey",
                            borderRadius: "8px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={image.url} // Change this based on your image object structure.
                            alt={`product-image-${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              top: "0%",
                              right: "0%",
                            }}
                          >
                            <CancelIcon
                              fontSize="large"
                              sx={{
                                color: "red",
                                ":hover": { fontSize: "2rem" },
                              }}
                              onClick={() => handleDeleteImage(index, image.id)}
                            />
                          </Box>
                          <ConfirmDialogImage />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <IconButton
                    onClick={nextSlide}
                    disabled={imagePreview.length <= 3}
                  >
                    <ArrowForward />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ textTransform: "capitalize" }}
          >
            {t("manage_product.cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ textTransform: "capitalize" }}
          >
            {loading ? t("manage_product.loading") : t("manage_product.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProductDialog;
