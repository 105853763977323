import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Component } from "react";

import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      language: require("./English/LanguageSelector.json"),
      navbar: require("./English/Navbar/Navbar.json"),
      footer: require("./English/Footer/Footer.json"),
      privacy_policy: require("./English/PrivacyPolicy/PrivacyPolicy.json"),
      about: require("./English/AboutUs/AboutUs.json"),
      contact: require("./English/ContactUS/ContactUs.json"),
      user: require("./English/User/User.json"),
      home: require("./English/Home/Home.json"),
      component: require("./English/Component/Component.json"),
      api: require("./English/Api/Api.json"),
      wish: require("./English/Wishlist/Wishlist.json"),
      product: require("./English/Products/Products.json"),
      notification: require("./English/Notification/Notification.json"),
      order: require("./English/BuyerOrder/BuyerOrder.json"),
      global: require("./English/DashGlobal/Global.json"),
      categories: require("./English/DashCategory/Categories.json"),
      manage_user: require("./English/DashManageUser/ManageUser.json"),
      manage_order: require("./English/DashManageOrder/ManageOrder.json"),
      manage_product: require("./English/DashManageProduct/ManageProduct.json"),
      dashboard: require("./English/Dashboard/Dashboard.json"),
      manage_home: require("./English/DashManageHomePage/ManageHomePage.json"),
      payment: require("./English/DashPayments/Payments.json"),
      dash_contact: require("./English/DashContact/Contact.json"),
      faq: require("./English/FAQ/FAQ.json"),
      chat: require("./English/Chat/Chat.json"),
      post: require("./English/News/News.json"),
      deliveryCost: require("./English/DeliveryCost/DeliveryCost.json"),
      logs: require("./English/Logs/Logs.json"),
    },
  },
  dr: {
    translation: {
      language: require("./Dari/LanguageSelector.json"),
      navbar: require("./Dari/Navbar/Navbar.json"),
      footer: require("./Dari/Footer/Footer.json"),
      privacy_policy: require("./Dari/PrivacyPolicy/PrivacyPolicy.json"),
      about: require("./Dari/AboutUs/AboutUs.json"),
      contact: require("./Dari/ContactUS/ContactUs.json"),
      user: require("./Dari/User/User.json"),
      home: require("./Dari/Home/Home.json"),
      component: require("./Dari/Component/Component.json"),
      api: require("./Dari/Api/Api.json"),
      wish: require("./Dari/Wishlist/Wishlist.json"),
      product: require("./Dari/Products/Products.json"),
      notification: require("./Dari/Notification/Notification.json"),
      order: require("./Dari/BuyerOrder/BuyerOrder.json"),
      global: require("./Dari/DashGlobal/Global.json"),
      categories: require("./Dari/DashCategory/Categories.json"),
      manage_user: require("./Dari/DashManageUser/ManageUser.json"),
      manage_order: require("./Dari/DashManageOrder/ManageOrder.json"),
      manage_product: require("./Dari/DashManageProduct/ManageProduct.json"),
      dashboard: require("./Dari/Dashboard/Dashboard.json"),
      manage_home: require("./Dari/DashManageHomePage/ManageHomePage.json"),
      payment: require("./Dari/DashPayments/Payments.json"),
      dash_contact: require("./Dari/DashContact/Contact.json"),
      faq: require("./Dari/FAQ/FAQ.json"),
      chat: require("./Dari/Chat/Chat.json"),
      post: require("./Dari/News/News.json"),
      deliveryCost: require("./Dari/DeliveryCost/DeliveryCost.json"),
      logs: require("./Dari/Logs/Logs.json"),
    },
  },
  ps: {
    translation: {
      language: require("./Pashto/LanguageSelector.json"),
      navbar: require("./Pashto/Navbar/Navbar.json"),
      footer: require("./Pashto/Footer/Footer.json"),
      privacy_policy: require("./Pashto/PrivacyPolicy/PrivacyPolicy.json"),
      about: require("./Pashto/AboutUs/AboutUs.json"),
      contact: require("./Pashto/ContactUS/ContactUs.json"),
      user: require("./Pashto/User/User.json"),
      home: require("./Pashto//Home/Home.json"),
      component: require("./Pashto/Component/Component.json"),
      api: require("./Pashto/Api/Api.json"),
      wish: require("./Pashto/Wishlist/Wishlist.json"),
      product: require("./Pashto/Products/Products.json"),
      notification: require("./Pashto/Notification/Notification.json"),
      order: require("./Pashto/BuyerOrder/BuyerOrder.json"),
      global: require("./Pashto/DashGlobal/Global.json"),
      categories: require("./Pashto/DashCategory/Categories.json"),
      manage_user: require("./Pashto/DashManageUser/ManageUser.json"),
      manage_order: require("./Pashto/DashManageOrder/ManageOrder.json"),
      manage_product: require("./Pashto/DashManageProduct/ManageProduct.json"),
      dashboard: require("./Pashto/Dashboard/Dashboard.json"),
      manage_home: require("./Pashto/DashManageHomePage/ManageHomePage.json"),
      payment: require("./Pashto/DashPayments/Payments.json"),
      dash_contact: require("./Pashto/DashContact/Contact.json"),
      faq: require("./Pashto/FAQ/FAQ.json"),
      chat: require("./Pashto/Chat/Chat.json"),
      post: require("./Pashto/News/News.json"),
      deliveryCost: require("./Pashto/DeliveryCost/DeliveryCost.json"),
      logs: require("./Pashto/Logs/Logs.json"),
    },
  },
};

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
