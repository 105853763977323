import React from "react";
import {
  Container,
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  CircularProgress,
  Alert,
  Divider,
} from "@mui/material";
import { parseISO, isValid, format } from "date-fns";
import useFetchObjects from "../../../api/useFetchObjects"; // Adjust the import path
import { useLocation, useNavigate } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import ImageIcon from "@mui/icons-material/Image";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { useTranslation } from "react-i18next"; // Import useTranslation

const News = () => {
  const { t } = useTranslation(); // Initialize translation
  const navigate = useNavigate();
  const location = useLocation();
  const postID = location?.state?.id;

  const {
    data: posts,
    isError,
    isLoading,
    error,
  } = useFetchObjects(["posts"], `posts/?id=${postID || ""}`);

  if (isLoading) {
    return (
      <Container sx={{ py: 8 }} maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <CircularProgress size={60} />
        </Box>
      </Container>
    );
  }

  if (isError) {
    return (
      <Container sx={{ py: 8 }} maxWidth="md">
        <Alert severity="error">
          Error:{" "}
          {error?.message || t("post.unknownError", "Something went wrong!")}
        </Alert>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 8 }} maxWidth="lg">
      <Typography
        variant="h2"
        component="h1"
        align="center"
        gutterBottom
        sx={{
          fontWeight: 700,
          color: "text.primary",
          letterSpacing: "0.5px",
          textTransform: "uppercase",
        }}
      >
        {t("post.latestNews")}
      </Typography>
      <Divider
        sx={{ mb: 6, mx: "auto", width: "80px", borderBottomWidth: 3 }}
      />

      {posts.map((post) => (
        <Card
          key={post.id}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            mb: 6,
            boxShadow: 4,
            borderRadius: 3,
            overflow: "hidden",
            transition: "transform 0.3s, box-shadow 0.3s",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: 8,
            },
          }}
        >
          {/* Image Section */}
          {post.image ? (
            <Box sx={{ position: "relative", width: { xs: "100%", md: 400 } }}>
              <CardMedia
                component="img"
                sx={{
                  width: "100%",
                  height: 250,
                  objectFit: "cover",
                  transition: "transform 0.4s ease, filter 0.4s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    filter: "brightness(1.1)",
                  },
                }}
                image={post.image}
                alt={post.title}
                loading="lazy"
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  p: 2,
                  bgcolor: "rgba(0, 0, 0, 0.5)",
                  color: "#fff",
                }}
              >
                <Typography variant="subtitle2">
                  {isValid(parseISO(post.created_at))
                    ? format(parseISO(post.created_at), "MMMM dd, yyyy")
                    : t("post.unknownDate")}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: { xs: "100%", md: 400 },
                height: 250,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f5f5f5",
                position: "relative",
              }}
            >
              <ImageIcon sx={{ fontSize: 80, color: "#9e9e9e" }} />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  p: 2,
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  {isValid(parseISO(post.created_at))
                    ? format(parseISO(post.created_at), "MMMM dd, yyyy")
                    : t("post.unknownDate")}
                </Typography>
              </Box>
            </Box>
          )}

          {/* Content Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              p: { xs: 3, md: 4 },
            }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  color: "text.primary",
                  position: "relative",
                  display: "inline-block",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: -5,
                    left: 0,
                    width: "30%",
                    height: "3px",
                    bgcolor: "primary.main",
                    borderRadius: 2,
                  },
                }}
              >
                {post.title}
              </Typography>
              {post.description && (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mb: 2, lineHeight: 1.6 }}
                >
                  {post.description.length > 200
                    ? `${post.description.substring(0, 200)}...`
                    : post.description}
                </Typography>
              )}

              {/* Product Section */}
              {post.product && post.product_details && (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    mt: 2,
                    p: 2,
                    bgcolor: "#f0f4ff",
                    borderRadius: 1,
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "#e3e9fc",
                    },
                  }}
                  onClick={() => navigate(`/products/${post?.product}`)}
                >
                  <Inventory2OutlinedIcon
                    color="primary"
                    sx={{ mr: 2, fontSize: 28 }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "text.primary", fontWeight: 600 }}
                  >
                    {t("post.product", { name: post?.product_details?.name })}
                  </Typography>
                </Box>
              )}
            </CardContent>

            <Box sx={{ mt: "auto" }}>
              <Divider sx={{ mb: 2 }} />
              <CardActions
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {post.link ? (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    href={post.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    endIcon={<LaunchIcon sx={{ margin: "0px 5px" }} />}
                  >
                    {t("post.learnMore")}
                  </Button>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      fontStyle: "italic",
                    }}
                  >
                    {t("post.noLinkAvailable")}
                  </Typography>
                )}
              </CardActions>
            </Box>
          </Box>
        </Card>
      ))}
    </Container>
  );
};

export default News;
