import React from "react";
import NotificationsList from "../../../web/pages/notificationList/Notifications";
import { Grid } from "@mui/material";
const Notifications = () => {
  return (
    <Grid container sx={{ maxHeight: 700, overflowY: "auto" }}>
      <NotificationsList />
    </Grid>
  );
};

export default Notifications;
